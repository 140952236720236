// import 'bootstrap/dist/css/bootstrap.min.css';
// import captchaImg from './captcha.jpg';
// import studentIMG from './2.png';
import React, { useState, useEffect } from 'react';
import { memo } from "react";

function Captcha(props) {

  const [user, setUser] = useState({username:""});
  const [showCaptchaText, setShowCaptchaText] = useState(true);
  const [showCaptchaBox, setShowCaptchaBox] = useState(true);
  const [showCaptchaVerfiedBtn, setShowCaptchaVerfiedBtn] = useState(false);


  const characters ='abc123';
  var counter=0;
  var captcha = generateString(6);
    // useEffect(()=>{
    //   captcha = generateString(6) // Function called here and save in captcha variable
    // })
  
    function generateString(length) 
    {
        let result = '';
        const charactersLength = characters.length;
         for ( let i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }
   let handleChange = (e) => {
     let name = e.target.name;
     let value = e.target.value;
     user[name] = value;
     setUser(user);
  }

  const onSubmit = e => 
  {

    var element =  document.getElementById("succesBTN");
    var inputData = document.getElementById("inputType");
     element.style.cursor = "wait";
     element.innerHTML  = "Checking...";
     inputData.disabled = true;
     element.disabled = true;

      var myFunctions = function()
      {
          if(captcha == user.username)
          {
            // setShowCaptchaText(false)
            // sessionStorage.setItem("showNextCatchaVerify","true")
            element.style.backgroundColor   = "green";
            element.style.cursor = "pointer";
            element.innerHTML  = "Captcha Verifed";
            element.disabled = false;
            inputData.disabled = false;
            inputData.value ='';
            var myFunction1 = function(){
                element.style.backgroundColor   = "#007bff";
                element.style.cursor = "pointer";
                element.innerHTML  = "Next";
                props.nextBtnClock();
                element.disabled = false;
                inputData.disabled = false;
                inputData.value ='';
              };
              setTimeout(myFunction1,1000);
            }
          else
          {

            // setShowCaptchaVerfiedBtn(false)       
            // setShowCaptchaText(true)
            element.style.backgroundColor   = "red";
            element.style.cursor = "not-allowed";
            element.innerHTML  = "Not Matched";
            element.disabled = true;
            //  element.disabled = true;

            var myFunction2 = function(){
              element.style.backgroundColor   = "#007bff";
              element.style.cursor = "pointer";
              element.innerHTML  = "Next";
              element.disabled = false;
              inputData.disabled = false;
              inputData.value ='';
            };
            setTimeout(myFunction2,1000);
          }
        }   
        setTimeout(myFunctions,1000); 
  };
  
  
   return (
    <div className="container">
      <div className="row mt-4">
          <div className="col-md-4">
          </div>
         
          <div className="col-md-8">
         
            <div>
                <h5 id="captcha info">Enter below text in Captcha</h5>
                <h4 id="captcha">{captcha}</h4>
                <div className="form-group row">
                    <input type="text" id="inputType" className="form-control"placeholder="Enter Captcha"
                    name="username"  onChange={handleChange} autoComplete="off" style={{width:"20%"}}
                    />
                <button type="button" id="succesBTN" onClick={onSubmit} className="btn btn-primary ml-1">Next</button>
              </div>
            </div>
           </div>
        </div>
      </div>
    );
}
export default memo(Captcha);