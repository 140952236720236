import React, { useState, useEffect, Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import './../css/Checkout.css';
import UserService from '../services/UserService';
import PageLoader from '../subComponents/PageLoader';
import PopupComplaintSuccess from './PopupComplaintSuccess';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// const userInfo = JSON.parse(sessionStorage.getItem('orderDetails')) || {
//     customerName: '',
//     mobile: '',
//     address: '',
//     email: '',
//     ciId: '',
//     id: ''
// };

function PopupComplaint({ showModalPopup, onPopupClose, orderID }) {
    // const [showModal, setShowModal] = useState(false);
    const [issues, setIssues] = useState([]); // All issues data
    const [subIssues, setSubIssues] = useState([]); // Subtype data for the second select box
    const [selectedIssueId, setSelectedIssueId] = useState(''); // Selected value of the first select box
    const [selectedSubIssueId, setSelectedSubIssueId] = useState(''); // Selected value of the second select box
    const [description, setDescription] = useState(''); // Complaint description
    // const [showAddressPopup, setShowAddressPopup] = useState(false);
    const [showPopupAlertState, setShowPopupAlertState] = useState(false);
    const [showPopupMsgState, setShowPopupMsgState] = useState('');
    const [pageLoader, setPageLoader] = useState(false);

    // State variables for form validation errors
    const [errors, setErrors] = useState({
        selectedIssueId: '',
        selectedSubIssueId: '',
        description: '',
    });

    const [userInfo, setUserInfo] = useState({
        customerName: '',
        mobile: '',
        address: '',
        email: '',
        ciId: '',
        id: ''
      });

    // Function to fetch issues data from API
    const fetchIssues = async () => {
        // Replace 'YOUR_API_ENDPOINT' with your actual API endpoint
        UserService.getCaseTyps().then(res => {
            if (res.data && res.data.length !== 0 && res.status === 200) {
                const data = res.data;
                setIssues(data);
                //const initialIssueId = data[0]?.id || ''; // Set the default selected issue id
                //setSelectedIssueId(initialIssueId);
                //updateSubIssues(initialIssueId, data); // Update sub-issues for the default selected issue
            } else {
                console.log(res.statusText);
            }
        }).catch(error => {
            console.error('Error fetching issues:', error);
            toast.warn('Error fetching issues, please try again later.', {
                position: toast.POSITION.TOP_CENTER,
            });
        });
    };

    const updateSubIssues = (issueId, allIssues) => {
        // console.log("issueId == ", issueId);
        if (!issueId) {
            setSubIssues([]); // Clear sub-issues if no issue is selected
            setSelectedSubIssueId(''); // Clear selected sub-issue
            return;
        }

        const selectedIssue = allIssues.find(issue => issue.id === parseInt(issueId));
        // console.log("selectedIssue= ", selectedIssue);
        const subTypes = selectedIssue ? selectedIssue.subType : [];

        setSubIssues(subTypes);
        // if (subTypes.length > 0) {
        //     setSelectedSubIssueId(subTypes[0].id); // Automatically select the first sub-issue
        // } else {
        //     setSelectedSubIssueId(''); // Clear selected sub-issue if no sub-types are available
        // }
        setSelectedSubIssueId('')
    };

    useEffect(() => {
        const orderDetails = sessionStorage.getItem('orderDetails');
        if (orderDetails) {
            setUserInfo(JSON.parse(orderDetails));
        }

        if (showModalPopup) {
            fetchIssues();
        }
    }, [showModalPopup]); // Fetch issues only when the modal opens

    // Effect to update sub-issues when selectedIssueId changes
    useEffect(() => {
        updateSubIssues(selectedIssueId, issues);
        // console.log("userInfo === ", userInfo.email);
    }, [selectedIssueId, issues]);

    // Handler for issue change
    const handleIssueChange = (event) => {
        setSelectedIssueId(event.target.value);
    };

    // Handler for sub-issue change
    const handleSubIssueChange = (event) => {
        setSelectedSubIssueId(event.target.value);
    };

    const handleClose = () => {
        setSelectedIssueId(''); // Reset to default
        setSelectedSubIssueId(''); // Reset to default
        setSubIssues([]);
        setDescription('');
        setErrors({ // Clear all error messages
            selectedIssueId: '',
            selectedSubIssueId: '',
            description: '',
        });
        onPopupClose(false);
    };

    // Handler for description change
    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };

    // Form validation function
    const validateForm = () => {
        const newErrors = {};
    
        if (!selectedIssueId) {
          newErrors.selectedIssueId = 'Please select a query type.';
        }
    
        if (!selectedSubIssueId) {
          newErrors.selectedSubIssueId = 'Please select a query sub-type.';
        }
    
        if (!description.trim()) {
          newErrors.description = 'Please describe your query.';
        }
        
        setErrors(newErrors);
    
        // Return true if there are no errors
        return Object.keys(newErrors).length === 0;
    };

    const showPopUpAlert = (status, msg) => {
        setShowPopupAlertState(status);
        setShowPopupMsgState(msg);
    };

    // Handler for form submission
    const handleFormSubmit = async (event) => {
        event.preventDefault(); // Prevent default form submission

        if (!userInfo.id) {
            toast.error(`Error: The Account id is required!`, {
                position: toast.POSITION.TOP_CENTER,
            });  
            handleClose();
            return;
        }
        if (!userInfo.ciId) {
            toast.error(`Error: The CI id is required!`, {
                position: toast.POSITION.TOP_CENTER,
            }); 
            handleClose();
            return;
        }
        if (!orderID) {
            toast.error(`Error: The Order id is required!`, {
                position: toast.POSITION.TOP_CENTER,
            }); 
            handleClose();
            return;
        }

        if (!validateForm()) {
            return; // Stop form submission if validation fails
        }

        const payload = {
            typeId: parseInt(selectedIssueId),
            subTypeId: parseInt(selectedSubIssueId),
            accountId: userInfo.id || '', // Replace with the actual accountId if dynamic
            ciId: userInfo.ciId || '', // Replace with the actual ciId if dynamic
            description: description,
            orderId: orderID,
        };
        // console.log("payload ooppoo ==== ", payload);
        try {
            setPageLoader(true);
            UserService.createComplain(payload).then(res => {
                // console.log("res ======rrr=========== ", res);
                if (res.data && res.data.length !== 0 && res.data.success === true) {
                    // toast.success(res.data.message, {
                    // position: toast.POSITION.TOP_CENTER,
                    // });
                    // Reset form fields after successful submission
                    setSelectedIssueId('');
                    setSelectedSubIssueId('');
                    setDescription('');
                    setErrors({});
                    handleClose();
                    setPageLoader(false);
                    showPopUpAlert(true, 'Thankyou for reaching out to us. Rest assured our representative will reach out to you within the next 48 hrs.');
                } else {
                    handleClose();
                    setPageLoader(false);
                    let errmsg = res.data.message || 'Error submitting query. Please try again.';
                    toast.error(errmsg, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            }).catch(error => {
                console.error('Error fetching issues:', error);
                // showPopUpAlert(true, 'Thankyou for reaching out to us. Rest assured our representative will reach out to you within the next 48 hrs.');
                handleClose();
                setPageLoader(false);
                if (error && error.response && error.response.data.length !== 0 && error.response.data.success === false) {
                    const formattedMessage = error.response.data.message.replace(/\[|\]/g, '').trim();
                    toast.error(`Error: ${formattedMessage}`, {
                        position: toast.POSITION.TOP_CENTER,
                    });  
                }else{
                    toast.error(`Error: ${error}`, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            });
        
        } catch (error) {
            handleClose();
            setPageLoader(false);
            console.error('Error submitting query:', error);
            toast.error('An unexpected error occurred. Please try again later.', {
                position: toast.POSITION.TOP_CENTER,
            });
        }
    };

    // const isShowModal = (status) => {
    //     handleClose();
    //     setShowModal(status);
    // };

    return (
        <Fragment key={orderID}>
            { pageLoader && <PageLoader/> }  
            <Modal
                show={showModalPopup}
                onHide={handleClose}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                id="model_custome_css"
            >
                <Modal.Header closeButton>
                    {/* <ToastContainer /> */}
                    <Modal.Title className="blackClourV2 text-center"></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className="contact_box_design">
                            <div className="col-xl-12">
                                <h4 className="complaint-title">Here to assist you</h4>
                                <div className="mt-3">
                                    <form className="form-complaint" onSubmit={handleFormSubmit}>
                                        <div className="form-row">
                                            <div className="form-group col-md-4">
                                                <label htmlFor="inputName">Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="inputName"
                                                    placeholder="Name"
                                                    disabled
                                                    defaultValue={userInfo.customerName}
                                                />
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label htmlFor="inputMobile">Mobile Number</label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    id="inputMobile"
                                                    placeholder="Mobile Number"
                                                    disabled
                                                    defaultValue={userInfo.mobile}
                                                />
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label htmlFor="inputEmail4">Email</label>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    id="inputEmail4"
                                                    placeholder="Email"
                                                    disabled
                                                    defaultValue={userInfo.email}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-md-4">
                                                <label htmlFor="inputCType">Query Type</label>
                                                <select
                                                    id="inputCType"
                                                    className="form-control"
                                                    value={selectedIssueId}
                                                    onChange={handleIssueChange}
                                                >
                                                    <option value="">
                                                        Select
                                                    </option>
                                                    {issues.map(issue => (
                                                        <option key={issue.id} value={issue.id}>
                                                            {issue.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                {errors.selectedIssueId && (
                                                    <small className="text-danger">{errors.selectedIssueId}</small>
                                                )}
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label htmlFor="inputCSType">Query Sub Type</label>
                                                <select
                                                    id="inputCSType"
                                                    className="form-control"
                                                    value={selectedSubIssueId}
                                                    onChange={handleSubIssueChange}
                                                >
                                                        <option value="">
                                                            Select
                                                        </option>
                                                        {subIssues.map(subIssue => (
                                                            <option key={subIssue.id} value={subIssue.id}>
                                                                {subIssue.name}
                                                            </option>
                                                        ))}
                                                </select>
                                                {errors.selectedSubIssueId && (
                                                    <small className="text-danger">{errors.selectedSubIssueId}</small>
                                                )}
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="inputAddress"
                                                placeholder="Describe your query"
                                                value={description}
                                                onChange={handleDescriptionChange}
                                            />
                                            {errors.description && (
                                                <small className="text-danger">{errors.description}</small>
                                            )}
                                        </div>
                                        <div className="button-complaint">
                                            <button type="submit" className="btn">
                                                REGISTER
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <PopupComplaintSuccess 
                showModalPopup={showPopupAlertState}
                onPopupClose={showPopUpAlert}
                showPopupMsg={showPopupMsgState}
            />
        </Fragment>
    );
}

export default PopupComplaint;
