import { useState, useEffect } from 'react';
import './../css/Style.css';
import './../css/complaint-management.css';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal } from 'react-bootstrap';

// const userInfo = JSON.parse(sessionStorage.getItem('orderDetails')) || {
//     ciName: '',
//     ciCode: '',
//     ciMobile: '',
//     centerName: ''
// };

function PopupCIDetails ({ isOpenCI, onCloseCI }) {
    const [userInfo, setUserInfo] = useState({
        ciName: '',
        ciCode: '',
        ciMobile: '',
        centerName: ''
    });

    useEffect(() => {
        const storedUserInfo = JSON.parse(sessionStorage.getItem('orderDetails')) || {
            ciName: '',
            ciCode: '',
            ciMobile: '',
            centerName: ''
        };
        setUserInfo(storedUserInfo);
    }, [isOpenCI]); // Re-fetch data when the modal opens

    if (!isOpenCI) return null;

    return (
        <>
            <Modal
                show={isOpenCI}
                onHide={onCloseCI}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                id="CI-details"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="CITitle">Relationship Manager Details</Modal.Title>
                </Modal.Header>
                <Modal.Body onClick={(e) => e.stopPropagation()} style={{ padding: '0px' }}>
                        <div className="CI-management">
                            {userInfo.ciCode ? (
                                <div className="card">
                                    <div className="card-body">
                                        <div className="first">
                                            <div>
                                                <h5 className="card-title">Name</h5>
                                                <span>{userInfo.ciName || 'NA'}</span>
                                            </div>
                                            <div>
                                                <h5 className="card-title">Mobile</h5>
                                                <span>{userInfo.ciMobile || 'NA'}</span>
                                            </div>
                                            <div>
                                                <h5 className="card-title">Email ID</h5>
                                                <span>{userInfo.ciEmail || 'NA'}</span>
                                            </div>
                                        </div>
                                    
                                    </div>
                                </div>
                            ) : (
                                <p>No Center Details Available!</p>
                            )}
                        </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default PopupCIDetails;
