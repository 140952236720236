import React from 'react'
import NavLinks from "./NavLinks";
import Bell from "./../images/bell.png" 
import  './component.css'



const Navigation=()=>
{
    return(
        <nav className="Navigation NavBarNaviation">
           
            {/* <li>
                <a>
                <img src={Bell} size ='40px'/></a>
            </li> */}
            <NavLinks/>
        </nav>
    );
}

export default Navigation;