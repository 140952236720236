export const bookNowClick = () =>
  {

    if(sessionStorage.getItem("journey")==="old")
    {
      window.location.assign('https://accounts.hindustantimes.com/buy-ht-newspaper-subscription?utm_source=htreaders&utm_medium=new_customer');
    }
    else
    {
      window.location.assign('https://accounts.hindustantimes.com/buy-ht-newspaper-subscription?utm_source=htreaders&utm_medium=new_customer');
      
    }
  }

  export const registrationGetCityName = (e) => 
  {
    if (e.toLowerCase() == "cpm") return 'Chandigarh';
    else if (e.toLowerCase() == "delhi") return 'Delhi NCR';
    else return e;
  }

  export const registrationGetCityNameOrder = (e) => 
  {
    if (e?.toLowerCase() == "cpm") return 'Chandigarh';
    else return e;
  }

  export const getUtmSource = ()=>
  {
    var url_string = window.location.href
    var url = new URL(url_string);

    if(url_string.includes("utm"))
    {
      var utm_source = url.searchParams.get("utm_source") || "no source";
      var utm_medium = url.searchParams.get("utm_medium") || "no medium";
      var utm_name = url.searchParams.get("utm_campaign") || "no name";
      sessionStorage.setItem("stg_utm_source",utm_source);
      sessionStorage.setItem("stg_utm_medium",utm_medium);
      sessionStorage.setItem("stg_utm_name",utm_name);

    }
  }



  export const bookNowClickBanner = () => {
    window.location.assign('https://accounts.hindustantimes.com/ht-hindustan-mint-newspaper-epaper-subscription?utm_source=htreaders&utm_medium=inpage_banner');
  }

  export const nonLoginUserRedirection = () =>
  {
    if(sessionStorage.getItem("journey")==="old")
    {
      window.location.assign('https://accounts.hindustantimes.com/buy-ht-newspaper-subscription?utm_source=htreaders&utm_medium=redirection');
    }
    else
    {
      window.location.assign('https://accounts.hindustantimes.com/buy-ht-newspaper-subscription?utm_source=htreaders&utm_medium=redirection');
    }

  }
  export const buyNowLandingPage = () => 
  {
    window.location.assign('https://www.htreaders.com/?msg=newCustomer&utm_source=banner');
  }

  export const refreshPage = () => {
    window.location.reload(true);
  };


  export async function getDataKeySame(url = "") {

    try {
      const USER_API_URL = process.env.REACT_APP_BASE_URL;
      const token = sessionStorage.getItem('preAuthAllSchemeToken');
      let response = await fetch(USER_API_URL +'/'+url, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+token, 
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrer: "no-referrer" // no-referrer, *client,
      });
  
      response = await response.json(); // parses JSON response into native JavaScript objects
  
      if (response && response.status === 200) 
      {
         return response;
      } 
      else
      {
         return response;
      }
    } catch (e) {
      console.log(e);
    }
  }