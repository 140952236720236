/**
 * @created_by : Mohit   
 * @Desc : Moengage - Click on checkout button on (order.js) page.
 */
export const moengageCheckoutBtn_Clicked = () =>
{

  if(process.env.REACT_APP_ENABLE_MOENGAGE==="true")
    {
    
      if(sessionStorage.getItem("createNewAccountActive")==="ON")
      {
          window.Moengage.track_event( "Acquisition Checkout Button Clicked", 
          {
            Mobile_Number: sessionStorage.getItem("stg_mobile_number"), Email_Address: sessionStorage.getItem("stg_emailAdress_Checkout_updated"),
            Campaign_Source: sessionStorage.getItem("stg_utm_source"),Campaign_Medium: sessionStorage.getItem("stg_utm_medium"), 
            Campaign_Name: sessionStorage.getItem("stg_utm_name"),
            Plan_Name: sessionStorage.getItem("stg_selectedPlan_Name"), Plan_Duration: sessionStorage.getItem("moe_acq_selected_yearPlan"),
            Plan_Price : sessionStorage.getItem("moe_acq_selected_planPrice"), Redirect_From : sessionStorage.getItem("stg_mo_redirectFrom"), Is_Email_Valid: true,
            User_Name: sessionStorage.getItem("customer_name"),
            Flat_House_No:sessionStorage.getItem("flat_no"),
            BLock_Street : sessionStorage.getItem("block_or_street"),
            Locality :sessionStorage.getItem("locality"),
            Select_City: sessionStorage.getItem("city"),
            Pincode : sessionStorage.getItem("pin_code")
            
          }
        )
      }

    }

}

/**
 * @created_by : Mohit   
 * @Desc : Moengage - Click on Proceed Button checkout page viewed (order.js) page.
 */
export const moengageCheckoutPage_Viewed = () =>
{

  if(process.env.REACT_APP_ENABLE_MOENGAGE==="true")
  {

     if(sessionStorage.getItem("createNewAccountActive")==="ON")
     {
        window.Moengage.track_event( "Acquisition Checkout Screen View", 
        {
           Mobile_Number: sessionStorage.getItem("stg_mobile_number"),Campaign_Source: sessionStorage.getItem("stg_utm_source"), 
           Campaign_Medium: sessionStorage.getItem("stg_utm_medium"), Registration_Status: sessionStorage.getItem("stg_status"), 
           Campaign_Name: sessionStorage.getItem("stg_utm_name"),Plan_Name: sessionStorage.getItem("stg_selectedPlan_Name"), 
           Plan_Duration: sessionStorage.getItem("moe_acq_selected_yearPlan"), Plan_Price : sessionStorage.getItem("moe_acq_selected_planPrice"), 
           Redirect_From : "Click on Proceed Button from Select City Page", Registered: "New User"
        }
       )
     } 
  }
}

/**
 * @created_by : Mohit   
 * @Desc : Moengage - Click Go Back button on (order.js) page.
 */
 export const moengage_Acq_CheckoutBackBtn_Clicked=()=>
    {      
      if(process.env.REACT_APP_ENABLE_MOENGAGE==="true")
      {
         sessionStorage.setItem("stg_mo_redirectFrom","Back Button Clicked from Acquisition Checkout page")
         window.Moengage.track_event("Acquisition Go Back Button Clicked", 
         {
            Mobile_Number: sessionStorage.getItem("stg_mobile_number"),
            Campaign_Source: sessionStorage.getItem("stg_utm_source"), 
            Campaign_Medium: sessionStorage.getItem("stg_utm_medium"), 
            Campaign_Name: sessionStorage.getItem("stg_utm_name"),
            Plan_Name: sessionStorage.getItem("stg_selectedPlan_Name"), 
            Plan_Duration: sessionStorage.getItem("stg_selectedPlan_Duration"),
            Plan_Price : sessionStorage.getItem("stg_selectedPlan_Price"),
            Redirect_From : sessionStorage.getItem("stg_mo_redirectFrom"),
            Registered: sessionStorage.getItem("stg_acq_userType")
         }
        )
      }
  }

  