import { imagesMapped } from "./../imageData";

import * as moment from "moment";
var filterImg;
let duplicateImageRemoved;
var printText = [];
var digitalText = [];

export function schemeDescription(orders) {
    printText = [];
    digitalText = [];
    var arrImageName = orders.split(";");
    filterImg = imagesMapped.filter(obj => arrImageName.includes(obj.name));
    duplicateImageRemoved = filterImg.filter((ele, ind) => ind === filterImg.findIndex(elem => elem.image === ele.image))
    var k = 0;
    var m = 0;

    filterImg.map(function (item, i) {
       var andImage = '';
       var digitalAndImage = '';

       if (item.imageBasis === "print") {
          andImage = '';

          if (k >= 1) {
             andImage = " , ";
          }

          // if (printTextLength.length - 1 === k) {
          //    andImage = " and ";
          // }

          // printText.push(andImage);
          printText.push(andImage, item.fullForm);

          k++;
       }
       if (item.imageBasis === "digital") {
          digitalAndImage = '';
          if (m >= 1) {
             digitalAndImage = " , ";
          }

          // if (digitalTextLength.length - 1 === m) {
          //    digitalAndImage = " and ";
          // }
          digitalText.push(digitalAndImage, item.fullForm)
          m++;
       }

    });
  return [printText, digitalText]
}



export function duplicateImageFilter(orders) {

    var arrImageName = orders.split(";");
    filterImg = imagesMapped.filter(obj => arrImageName.includes(obj.name));
    duplicateImageRemoved = filterImg.filter((ele, ind) => ind === filterImg.findIndex(elem => elem.image === ele.image))
    return duplicateImageRemoved;
}

export function offerImageFilter(orders) {

   var arrImageName = orders.split(";");
   filterImg = imagesMapped.filter(obj => arrImageName.includes(obj.name));
   duplicateImageRemoved = filterImg.filter((ele, ind) => ind === filterImg.findIndex(elem => elem.image === ele.image))
   return duplicateImageRemoved;
}


var oneSelectedCoupons =[];
export function mapCoupons(coupons, isChecked){
  coupons.forEach(function (entry) {
        var singleObj = {};
        singleObj['couponNumber'] = entry.couponNumber;
        singleObj['sapOrderNumber'] = entry.sapOrderNumber;
        singleObj['expiryDate'] = moment(entry.expiryDate).format('YYYY-MM-DD');
        singleObj['monthOfRedemption'] = entry.monthOfRedemption;
        singleObj['location'] = entry.location;
        singleObj['isChecked'] = isChecked;
        singleObj['couponPreference'] = entry.couponPreference;
        singleObj['validUpto'] = moment(entry.validUpto).format('YYYY-MM-DD');
        singleObj['publication'] = entry.publication;
        singleObj['rate'] = entry.rate;
        singleObj['tenure'] = entry.tenure;
        singleObj['type'] = entry.type;
        oneSelectedCoupons.push(singleObj);
       
    });
     return oneSelectedCoupons;
}