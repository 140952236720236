import React, { PureComponent } from 'react'
import PageLoader from '../subComponents/PageLoader';

export class ShowRazorPayPaymentPage extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            transToken: this.props.txnToken,
            orderId: sessionStorage.getItem('linkId'),
            mId: sessionStorage.getItem('mId'),
            pageLoader:true
        };
        this.options = {
            "modal": { escape: false, ondismiss: this.props.onDismissRazorpay},
            "key": this.state.mId, // Enter the Key ID generated from the Dashboard
            "amount": this.props.price*100 + "", // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            "currency": "INR",
            "name": "HT Media",
            // "description": "Test Transaction",
            "timeout":420,
            "image": "https://example.com/your_logo",
            "order_id": this.state.transToken, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
            "callback_url": process.env.REACT_APP_BASE_URL +"/apis/subscription/v1/reNewScheme/payments/razorpaycallback",
            "prefill": {
                "name": this.props.name,
                "email": this.props.email,
                "contact": this.props.mobile
            },
            "notes": {
                "address": "Razorpay Corporate Office"
            },
            "theme": {
                "color": "#3399cc"
            }
            
        };
        this.proceedToPayment = this.proceedToPayment.bind(this);

    }

    proceedToPayment() {
        var rzp1 = new window.Razorpay(this.options);
        rzp1.open();
        this.setState({ pageLoader: false});
    }
    componentDidMount() 
    {
        this.setState({ pageLoader: true});
    }
    render(){
        this.proceedToPayment();
        return (<div>
                {this.state.pageLoader && 
                     <PageLoader/> }
        </div>)
    }
}

export default ShowRazorPayPaymentPage