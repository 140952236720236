import React, { Component,useState,useEffect,Suspense} from 'react';
import Tnc from './components/tnc';
import PageLoader from './subComponents/PageLoader';
import UserConsent from './subComponents/UserConsent';
import { ConsentProvider } from './subComponents/ConsentContext';

import ReactGA from "react-ga";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import PreAuth from './components/PreAuth';
import withClearCache from "./ClearCache"
import Order from "./components/Acquisition/Order";
import TrueCallar from './sso/TrueCaller';

import SSOLogin from './sso/LoginSSO';
import SSOLogout from './sso/LogoutSSO';
import SsoToken from './sso/SsoToken';

let authUser,journeyType;
let users;

const MobileLoginNJ = React.lazy(() => import('./components/MobileLoginNJ'))
const ErrorPage = React.lazy(() => import('./components/ErrorPage'))
const HomeNJ = React.lazy(() => import('./components/HomeNJ'))
const RedeemCoupon = React.lazy(() => import('./components/RedeemCoupon'))
const DeliveryAddress = React.lazy(() => import('./pages/DeliveryAddress'))
const VendorDetailsChange = React.lazy(() => import('./components/VendorDetailsChange'))
const AcquisitionLandingPage = React.lazy(() => import('./pages/Acquisition/AcqHomepage'))
const SuccessPage = React.lazy(() => import('./components/SuccessPage'))
const TermsConditions = React.lazy(() => import('./components/TermsConditions'))
const FAQ = React.lazy(() => import('./components/FAQ'))
const ComplaintManagement = React.lazy(() => import('./components/ComplaintManagement'))

class MainApp extends Component {

  constructor(props) 
  {
    super(props);
    this.state = 
    {
      preAuthRedirectLoading:true
    }
    

    const url = new URL(window.location.href);
    const params = url.searchParams;
    const paramValue = params.get('sso_source');
    // console.log("TRUECALLER PARAM", paramValue);
   
    
    if(window.location.href.includes("unique_code"))
    {
      var urlString = window.location.search;
      var urlParams  = new URLSearchParams(urlString);
      var uniqueCodeValue = urlParams.get('unique_code')
      sessionStorage.removeItem('journey');
      sessionStorage.removeItem('orderDetails');
      authUser = <PreAuth unique_code={uniqueCodeValue}></PreAuth> 
    }
    if(window.location.href.includes("sso_source"))
    {
      authUser = <TrueCallar></TrueCallar> 

    }
    if(window.location.href.includes("ssotoken"))
    {
      authUser = <SsoToken></SsoToken> 
    }
    else if(window.location.href.includes("TXN_SUCCESS"))
    {
      sessionStorage.setItem("bookinFormNumber", window.location.search.slice(1).split("&")[1].split("=")[1]);

      if(sessionStorage.getItem("createNewAccountActive")==="ON")
      {
        authUser = <Redirect to="/successPage" />
      }
      else
      {
        authUser = <Redirect to="/deliverAddress" />
      }
      
    }
    else if(window.location.href.includes("deliverAddress"))
    {
    
          authUser = <Redirect to="/deliverAddress" />
    
    }   
    else if(window.location.href.includes("newCustomer"))
    {
      if (paramValue =='truecaller')
      {
       authUser = <TrueCallar></TrueCallar>
      }
      else{
       authUser = <AcquisitionLandingPage></AcquisitionLandingPage>
      } 
    }
    else if(window.location.href.includes("termsCondition"))
    {    
      authUser = <TermsConditions></TermsConditions>    
        }
    else if(window.location.href.includes("/ssoconnect_staging_login.html"))
    {    
      authUser = <TermsConditions></TermsConditions>          
    }
    else if(window.location.href.includes("logoutSSO"))
    {    
      authUser = <SSOLogout></SSOLogout>    
    }
    else if(window.location.href.includes("loginSSO"))
    {    
      authUser = <SSOLogin></SSOLogin>    
    }
    else
    {
      if(!window.location.href.includes("newCustomer") || sessionStorage.getItem('preAuth')==="OFF")
      {
        users = JSON.parse(sessionStorage.getItem('orderDetails'));
      }
      else
      {
        sessionStorage.setItem("preAuth", "OFF")
        sessionStorage.removeItem('orderDetails')
      }
      if (users != null) 
      {
        sessionStorage.removeItem("showHeader");
        sessionStorage.setItem("showHeader",true)
        // authUser = <HomeNJ ></HomeNJ>
      
        if (window.location.pathname=="/") 
        {
        
          authUser = <HomeNJ></HomeNJ>
        }
      }
      else if(window.location.href.includes("login.php"))
      {
  
        authUser = <MobileLoginNJ></MobileLoginNJ> 
      }

      else 
      {
        authUser = <TrueCallar></TrueCallar> 

        // authUser = <MobileLoginNJ></MobileLoginNJ>    
      }
    
      if (window.location.search !== "")
      {
        if (window.location.search.split("=")[1].split("&")[0].toString() === "TXN_SUCCESS" ) 
        {
          sessionStorage.setItem("bookinFormNumber", window.location.search.slice(1).split("&")[1].split("=")[1]);

          authUser = <Redirect to="/deliverAddress" />
        }

        if(window.location.href.includes("deliverAddress"))
        {
    
          authUser = <Redirect to="/deliverAddress" />
    
        }

        if(window.location.href.includes("faq"))
        {
    
          authUser = <Redirect to="/faq" />
    
        }
        
        if(window.location.href.includes("errorPage"))
        {
    
          authUser = <Redirect to="/errorPage" />
    
        }

        if (window.location.search.split("=")[1].split("&")[0].toString() === "TXN_ERROR") 
        {
          authUser = <Redirect to="/errorPage" />
        }

        if (window.location.search.split("=")[1].split("&")[0].toString() === "TXN_FAILED") 
        {
          authUser = <Redirect to="/errorPage" />
        }

      }
      if (window.location.href !== "") 
      {
        if (window.location.href.split("=")[1] === "tnc")
        {

          authUser = <Redirect to="/tnc" />
        }

        if (window.location.href.split("=")[1] === "redeem")
        {
          authUser = "";
          authUser = <Redirect to="/redeem" />
        }
        if (window.location.href.split("=")[1] === "query-management")
        {
          authUser = "";
          authUser = <Redirect to="/query-management" />
        }
        if (window.location.href.split("=")[1] === "vendorChange")
        {
          authUser = "";
          authUser = <Redirect to="/vendorChange" />
        }

      }
      if (sessionStorage.getItem('paymentDone') === "true") 
      {
        sessionStorage.removeItem("showHeader");
        sessionStorage.setItem("showHeader","ON")
        authUser = <HomeNJ ></HomeNJ>
      }

      this.state = {
        auth: {
          loggedIn: sessionStorage.getItem("AuthInfo") !== null,
          succesShow: false,
        }

      };

    }
  }

  componentDidMount()
  {

    // ReactGA.initialize("UA-173262539-6");
    // ReactGA.initialize("UA62539-P1");


    // if(sessionStorage.getItem('journey') ==="new")
    // {
    //   ReactGA.ga('send','pageview','/loginNJ');
    // }
    // else
    // {
    //   ReactGA.ga('send','pageview','/login');
    // }
  }

  render()
  {

    return (
      <div>
     {authUser}
        <Suspense fallback={<h1>Still Loading…</h1>}>
            <Switch>
            <Route exact path="/errorPage" component={ErrorPage} />
            <Route exact path="/redeem" component={RedeemCoupon} />
            <Route exact path="/faq" component={FAQ} />
            {/* <Route exact path="/" component={HomeNJ} /> */}
            <Route exact path="/query-management" component={ComplaintManagement} />
            <Route exact path="/login.php" Redirect to="/" />
            <Route exact path="/deliverAddress"component={DeliveryAddress} />
            <Route exact path="/vendorChange"component={VendorDetailsChange} />
            <Route exact path="/successPage"component={SuccessPage} />  
            </Switch>            
          </Suspense>
      </div>
    )
  }
}
const ClearCacheComponent = withClearCache(MainApp);

// const ClearCacheComponent = React.lazy(() =>  withClearCache(MainApp));
export default function App() 
{    

 const [isLoading, setLoading] = useState(true);

  useEffect(() => 
  {
    setTimeout(async ()=> 
    {
      setLoading(false)

    },0) 
    
  }, []);

  if(isLoading)
  {
    return(
      <div>
        <PageLoader/> 
      </div>
     
    )
  }
  else
  {
    return(
      <Suspense fallback={<h1>Loading…</h1>}>
        <ClearCacheComponent />
      </Suspense>
    )
    
  } 
}


