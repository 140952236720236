import complaintsIcon from "../images/Profile-Menu/complaints.svg";
import React, { useEffect, useState } from 'react';

const InstallButton = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [installButtonText, setInstallButtonText] = useState('Install App');
  const [installButtonVisible, setInstallButtonVisible] = useState(false);
  const [installMessage, setInstallMessage] = useState('');

  useEffect(() => {
    // Check if the app is running in standalone mode (already installed)
    if (window.matchMedia('(display-mode: standalone)').matches) {
      // If the app is in standalone mode (already installed), hide the button
      setInstallButtonVisible(false);
    } else {
      // If not in standalone mode, check if it's already installed
      if (window.navigator.standalone || window.matchMedia('(display-mode: fullscreen)').matches) {
        // App is installed but running in browser (not standalone)
        setInstallButtonText('Go To App'); // Set button text to "Go To App"
        setInstallButtonVisible(true); // Show the button to open the app
      } else {
        // App is not installed, listen for install prompt
        setInstallButtonText('Install App'); // Set button text to "Install App"
      }
    }

    // Handle the beforeinstallprompt event (browser triggers this)
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      // Stash the event so it can be triggered later
      setDeferredPrompt(e);
      // Show the install button
      setInstallButtonVisible(true);
    };

    // Listen for the beforeinstallprompt event
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    // Listen for the appinstalled event (when app gets installed)
    window.addEventListener('appinstalled', () => {
      // Display a success message and change the button to "Go To App"
      setInstallMessage('App installed successfully!');
      setInstallButtonText('Go To App'); // Change button to "Go To App"
      setInstallButtonVisible(true); // Show the button in the browser
    });

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
      window.removeEventListener('appinstalled', () => {});
    };
  }, []);

  const handleInstallClick = () => {
    if (installButtonText === 'Go To App') {
      // If the button says "Go To App", redirect the user to the app
      window.location.href = window.location.origin; // Redirect to the app URL in the browser
    } else if (deferredPrompt) {
      // Trigger the install prompt
      deferredPrompt.prompt();
      // Wait for the user's response
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
          setInstallMessage('App installation is in progress...');
        } else {
          console.log('User dismissed the install prompt');
          setInstallButtonVisible(true); // Show the button again if dismissed
        }
        // Clear the deferredPrompt variable
        setDeferredPrompt(null);
      });
    }
  };

  return (
    <>
      {installButtonVisible && (
        <a id="install-button" onClick={handleInstallClick}>
        <img className="mr-2" src={complaintsIcon} /><span>{installButtonText}</span></a>
      )}

      {installMessage && (
        <div className="popup-message">{installMessage}</div>
      )}
    </>
  );
};

export default InstallButton;
