import React from 'react';
// Function to generate a background color based on a letter
const getBackgroundColor = (letter) => {
  const colors = [ '#CC4629', '#29CC46', '#2946CC', '#CC2985', '#CC7F29', '#29CCCA', 
    '#8529CC', '#C2CC29', '#29CC74', '#CC2929',
  ];
  // Generate an index from the letter
  const index = letter.toUpperCase().charCodeAt(0) % colors.length;
  return colors[index];
};
const ProfileIcon = ({ name }) => {
  // Get the first letter of the name
  const trimmedName = name ? name.trim() : '';
  const firstLetter = trimmedName ? trimmedName.charAt(0).toUpperCase() : '?';
  // Get a background color based on the first letter
  const backgroundColor = getBackgroundColor(firstLetter);
  const style = {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: backgroundColor,
    color: '#fff',
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    fontSize: '24px',
    fontWeight: 'bold',
  };
  return <div style={style}>{firstLetter}</div>;
};
export default ProfileIcon;