import React, { Component } from 'react';
import AcqHeader from "../../subComponents/AcqHeader";
import '../../css/Acquisition.css'
// import acqPublication from "../../images/Acquisition/publication.png"
import PageLoader from '../../subComponents/PageLoader';
import UserService from '../../services/UserService';
// import Login from './Login';
import acqPublication from '../../images/Acquisition/publication.png'
// import acqPublication from "../  /../images/Acquisition/publication.png"

export default class AcqCheckout extends Component 
{
    constructor(props) {  
        super(props);  
        this.state = 
        { 
            saveValue:"",
            yearPlan:"",
            coverPrice:"",
            discountedPrice:"",
            perDayValue:"",
            pageLoader:true,
            defaultLowestScheme:[],
            cityList:[],
            selectedCityIndex:'',
            showPincodeBar:false,
            showProeedBtn:false,
            showAcquisitionModel:true,
            showLoginModel:false


        }

    }

  render() 
  {
    return (
        <div>
            <div>
                <div className="container main-div">
                    <div className="stepNavBar mx-auto mt-4">
                        <ul>
                            <li className="active ">
                                <div className="stepItem blackClourV2">
                                    <span>1</span>
                                    Registration
                                </div>
                            </li>
                            <li className="active ">
                                <div className="stepItem blackClourV2">
                                    <span>2</span>
                                    Checkout
                                </div>
                            </li>
                            <li>
                                <div className="stepItem blackClourV2">
                                    <span>3</span>
                                    Delivery Detail
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}
  
// export default AcqLogin;