import React, { Component, Fragment } from 'react';  
import { Modal,Button } from 'react-bootstrap';  
import './../css/Checkout.css';

class PopupRemoveCouponCode extends Component {  
    constructor(props) {  
        super(props);  
        this.state = {  
            showModal: false,
        };  
    }  
    
   componentDidMount() 
   {
   } 
  
    // isShowModal = (status) => {  
    //     this.handleClose();  
    //     this.setState({ showModal: status });  
    // }  
  
    cancelClose = () => 
    { 
        this.props.onPopupClose(false);  
    }  

    confirmClose = () => 
    { 
        this.props.removeCoupon();
        this.props.onPopupClose(false);  
    } 

  
    render() 
    {  
        return (  
            <Fragment>  
                <Modal show={this.props.showModalPopup}
                    size="lg"  
                    aria-labelledby="contained-modal-title-vcenter"  
                    centered  
                >  
                <Modal.Body>  
                        <div>
                            <div className="container t-and-c">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div>
                                            <h4 className="text-center" style={{ color: 'red', fontSize : "18px" }} >{"Before changing the plan, please confirm you would like to remove the applied coupon?"}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>  

                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.cancelClose} >Cancel</Button>
                        <Button variant="primary" onClick={this.confirmClose} >Confirm</Button>
                    </Modal.Footer>
                </Modal >  
            </Fragment > 
        );  
    }  
}  
  
export default (PopupRemoveCouponCode); 