import React, { Component } from 'react';
import acqLogo from '../images/Acquisition/newcustomerlogo.png'
import '../css/Acquisition.css'
 
class AcqHeader extends Component 
{

    redirectToHome() {
        window.location.href = '/newjourney';
      
     }
    render() {
      return (
            // <div className="navigation-wrap bg-light start-header start-style">
            // <div className="container">
            //     <div className="row">
            //         <div className="col-12">
            <div>
                        {/* <nav className="navbar navbar-expand-md navbar-light">
                            
                            <a className="navbar-brand" target="_blank"><img src={acqLogo} onClick={this.redirectToHome} alt="main Logo"/></a>    
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="ml-auto">
                                <ul className="navbar-nav ml-auto py-4 py-md-0">
                                    <li className="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
                                        <a className="nav-link" href="contactUs.html"> Call Us <br></br> Delhi-01160004242 <br></br>Mumbai-02267764242</a>
                                    </li>
                                </ul>
                            </div>
                        </nav> */}

    {/* position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    text-align: center;
    margin: auto;

    style={{ color: 'red', font-weight : 400, font-size: 13px }} */}

                    <nav className="navbar navbar-inverse header-shadow" style={{backgroundColor: "white"}} role="navigation">
                    <div className="container">
                    
                        <div className="navbar-brand1 navbar-brand"  target="_blank"><img src={acqLogo} alt=""/></div>   
                        <div className="ml-auto" id="">
                        <ul className="nav navbar-nav ">
                            <li><div className="nav-link blackClourV2" href="#">Call Us <br/>Delhi-01160004242 <br/>Mumbai-02267764242</div></li>
                        </ul>
                        </div>
                    </div>
                    </nav>
                        
                    </div>
        //         </div>
        //     </div>
        // </div>
   )
    }
  } 
  export default AcqHeader;