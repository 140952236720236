import React, { Component, Fragment } from 'react';  
import { Modal } from 'react-bootstrap';
import alertIcon from "../images/checkout/alert-icon.svg";
import './../css/Checkout.css';

class PopupAddressChange extends Component {  
    constructor(props) {  
        super(props);  
        this.state = {  
            showModal: false,
        };  
    }  
    
   componentDidMount() 
   {
   }
  
    isShowModal = (status) => {  
        this.handleClose();  
        this.setState({ showModal: status });  
    }  
  
    handleClose = () => {
        this.props.onPopupClose(false);  
    }  

  
    render() 
    {  
        return (  
            <Fragment>  
                <Modal show={this.props.showModalPopup} onHide={this.handleClose}  
                    size="lg"  
                    aria-labelledby="contained-modal-title-vcenter"  
                    centered
                    className="saveAddress"
                >  
                    <Modal.Header closeButton>  
                        <Modal.Title id="saveAdd">  
                            <img src={alertIcon} />
                         </Modal.Title>  
                    </Modal.Header>  
					<Modal.Body>  
                        <div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12 t-and-c-div">
                                        <h4 className="text-center">{"Congratulations!"}</h4>
                                        <p className="text-center">{this.props.showPopupMsg}</p>
                                        <button className="btn" onClick={this.handleClose}>OK</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>  
                </Modal >  
            </Fragment > 
        );  
    }  
}  
  
export default (PopupAddressChange); 