import React, { PureComponent } from 'react'
export class ShowPaytmPaymentPage extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            transToken: this.props.txnToken,
          
            orderId: sessionStorage.getItem('linkId'),
            mId: sessionStorage.getItem('mId')
        };

    }

    componentDidMount() {
       
         document.getElementById('paytm').submit();
    }
    render() {

        const url = process.env.REACT_APP_PAYTM_BASE_URL +'?mid='+this.state.mId+'&orderId=' + this.state.orderId;
        return (
            <div>
                <center>
                    <h1>Please do not refresh this page...</h1>
                </center>
                <form method="post" ref="paytm" id="paytm" action={url} name="paytm">
                    <input type="hidden" name="mid" value={this.state.mId} />
                    <input type="hidden" name="orderId" value={this.state.orderId} />
                    <input type="hidden" name="txnToken" value={this.state.transToken} />
                </form>
            </div>
        )
    }
}

export default ShowPaytmPaymentPage