import React, { PureComponent } from 'react'
import mainLogo from './../images/mainLogo.png';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import './../css/Style.css';
import Navigation from "./Navigation";
import MobileNavigation from "./MobileNavigation";
import  './component.css'

export default class LoggedInHeader extends PureComponent 
{

    constructor(props) {
        super(props);
        this.state = 
        {
           showHeader : sessionStorage.getItem("showHeader")
        }
    }
    
    redirectToHome() {
        window.location.href = '/';
    }
    
    render() 
    {
        if (this.state.showHeader==="OFF") 
        {
        return <div/> 
        }
        else
        {
        return (
            <div className="navigation-wrap bg-light start-header start-style">
                <div className="container">
                    <div className="row nav-main">
                        {/* <div className="col-12 nav-main"> */}
                            <div className="brand-name col-4">
                                <a className="navbar-brand" target="_blank">
                                    <img className="img-fluid" src={mainLogo} alt="main Logo" onClick={this.redirectToHome} />
                                </a>
                            </div>
                            <div className="col-8 NavBar">
                                <MobileNavigation/>
                                <Navigation/>
                            </div>
                            
                        {/* </div> */}
                    </div>
                </div>
            </div>
        )
        }
    }
}