import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import './css/sso.css';
import Order from '../components/Acquisition/Order';
import UserService from '../services/UserService';
import PageLoader from '../subComponents/PageLoader';
import Home from '../components/HomeNJ';
import AcquisitionLandingPage from '../pages/Acquisition/AcqHomepage';
import MobileLoginNJ from '../components/MobileLoginNJ';
import AuthService from '../services/AuthService';


class SsoToken extends Component{


    constructor(props) {  
        super(props);  
        this.state = {  
            
            showLoader:true,
            showHomePage:false,
            showNewCustomerPage:false,
            showOrderPage:false,
            showLoginPage:false,

           
        };  
    }  
  

    componentDidMount(){ 

    
        AuthService.getAuth("htprint", "htprint").then((res)=>sessionStorage.setItem("AuthInfo", res?.token));
        // console.log("AUTH Token",sessionStorage.getItem('AuthInfo'))
        const query = new URLSearchParams(this.props.location.search);
        const token = query.get("ssotoken");

        if(token===undefined ||token===null || token==="")
        {

            this.setState({ showNewCustomerPage: false })
            this.setState({ showOrderPage: false })
            this.setState({ showLoader: false })
            this.setState({ showHomePage:true })
            this.setState({ showLoginPage: false })

        }
        else
        {
            setTimeout(()=>
            {
            UserService.getSSOTrueCaller(token).then(response => 
            {
                sessionStorage.setItem("stg_loggedInHeader","ON");
                if (response.data !== "") 
                {
                    if(response.data.id !==null)
                    {
                        sessionStorage.setItem("stg_mobile_number",response.data.mobile)
                        sessionStorage.setItem("orderDetails", JSON.stringify(response.data));
                        sessionStorage.setItem("default_gateway", response.data.defaultGateway)
                        sessionStorage.setItem("stg_vendor_name",response.data.vendorName)
                        sessionStorage.setItem("stg_vendor_mobile",response.data.vendorMobile);
                        sessionStorage.setItem("stg_vendor_code",response.data.vendorCode);
                        this.setState({ showNewCustomerPage: false })
                        this.setState({ showOrderPage: false })
                        this.setState({ showLoader: false })
                        this.setState({ showLoginPage: false})
                        this.setState({ showHomePage: true })

                    }

                    else if(window.location.href.includes("ssotoken"))
                    {
        
                        if(response.data.mobile!==null)
                        {
                            sessionStorage.setItem("stg_mobile_number",response.data.mobile)
                        }
                        else
                        {
                            sessionStorage.setItem("stg_mobile_number","")

                        }
                        this.setState((prevState) => ({
                            orderData: {
                            ...prevState.orderData,
                            planId: sessionStorage.getItem("stg_scheme_id"),
                            planName: sessionStorage.getItem("stg_selectedPlan_Name"),
                            planPrice: sessionStorage.getItem("moe_acq_selected_planPrice"),
                            coverPrice: sessionStorage.getItem("moe_acq_selected_coverPrice"),
                            planDuration: sessionStorage.getItem("moe_acq_selected_yearPlan"),
                            cityName: sessionStorage.getItem("stg_city"),
                            cityPincode: sessionStorage.getItem("moe_acq_Pincode"),
                            
                            },
                        }));
                        this.setState({ showHomePage: false })
                        this.setState({ showNewCustomerPage: false})
                        this.setState({ showLoader: false })
                        this.setState({ showLoginPage: false })
                        this.setState({ showOrderPage:true })

                    }
                    else
                    {
                        
 
                        this.setState({ showHomePage: false })
                        this.setState({ showOrderPage: false })
                        this.setState({ showLoader: false }) 
                        this.setState({ showLoginPage: false })
                        this.setState({ showNewCustomerPage: true })
                    }
                }
                }).catch(error => 
                    {
                        console.log("SSO Error",error)
                    })
            },1000)
        }
    }
       
    
    


  render()
  {
  return (
    <div className="App blackClourV2">
                
    {this.state.showLoader ===true ?
    (
        <PageLoader/> 
    )
    :(     
        <div>
            {this.state.showLoginPage ===true &&
              <MobileLoginNJ/> 
            }
            {this.state.showNewCustomerPage ===true &&
              <AcquisitionLandingPage/> 
            }
            {this.state.showHomePage ===true &&
              <Home/> 
            }
            {this.state.showOrderPage ===true &&
              <Order/> 
            }
         
{/* 


            {this.state.showHomePage ===true ?
            (
                <Home/> 
            ):
            (
                <Order orderData={this.state.orderData} />

            )} */}

        </div>
    )
    }
</div>
  )
  }

}

export default withRouter(SsoToken);
