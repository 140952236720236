import * as React from "react";
import { useState, useEffect } from "react";
import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import '@fontsource/poppins';
import addressBook from "../../images/Acquisition/address_book_notebook_icon.svg";
import contactIcon from "../../images/Acquisition/contact_icon.svg";
import UserService from "../../services/UserService";
import ShowPaytmPaymentPage from "../ShowPaytmPaymentPage";
import ShowRazorPayPaymentPage from "../ShowRazorPayPaymentPage";

import { moengageCheckoutBtn_Clicked,moengageCheckoutPage_Viewed,moengage_Acq_CheckoutBackBtn_Clicked } from "../../common/moengageFunction";
import TermsPopup from "../../subComponents/TermsPopup";
import {registrationGetCityNameOrder } from "../../common/commonFuntion";
import ReactGA from 'react-ga';

function Order({ orderData }) {
  const [data, setData] = useState({});
  const [paytmPageShow, setPaytmPageShow] = useState(false);
  const [gateway, setGateway] = useState("Razorpay");
  const [transToken, setTransToken] = useState("");
  const [mid, setMid] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [termsErrmsg, setTermsErrmsg] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [mobileError, setMobileError] = useState();
  const [showPopupAlertState, setShowPopupAlertState] = useState(false);
  const [pincodeGiven, setPincodeGiven] = useState(false);
  const [utmSource, setUtmSource] = useState();
  const baseUrl = window.location.origin;


  const pubFullName = {
    HT: "Hindustan Times Newspaper",
    Mint: "Mint Newspaper",
    BP: "Mint Newspaper",
  };

  useEffect(() => {
    data.city = registrationGetCityNameOrder(orderData?.cityName ?? sessionStorage.getItem("stg_city"));
    data.pincode = orderData?.cityPincode ?? sessionStorage.getItem("moe_acq_Pincode");
    data.mobile_no = sessionStorage.getItem("stg_mobile_number")
      ? sessionStorage.getItem("stg_mobile_number")
      : "";
    setData({ ...data });
    moengageCheckoutPage_Viewed();
    ReactGA.ga('send','pageview','/AcquisitionShippingAddress');
    if(orderData?.cityPincode!=="")
    {
      setPincodeGiven(true)
    }

    const url = new URL(window.location.href);
    // Get the value of a specific query parameter
    let utmSourceParam = url.searchParams.get('utm_source') ? url.searchParams.get('utm_source') : null;
    if (utmSourceParam && (utmSourceParam.toUpperCase() === 'MYGATE' || utmSourceParam.toUpperCase() === 'SMS' || utmSourceParam.toUpperCase() === 'EMAIL' || utmSourceParam.toUpperCase() === 'WHATSAPP')) {
      // Do something with the query parameter value
      setUtmSource(utmSourceParam);
    }

    if(sessionStorage.getItem("stg_mobile_number")!=undefined && sessionStorage.getItem("stg_mobile_number")!=''){
      UserService.addNewLeadGenerate(sessionStorage.getItem("stg_mobile_number"),sessionStorage.getItem("stg_scheme_id"), utmSourceParam).then((response) => {
        if(response.data.success){
        let leadId = response.data.lead_id;
        sessionStorage.setItem("lead_id",leadId);
        }
    })
    }
  }, []);

  const handleChange = (e) => {
    e.persist();
    let { name , value } = e.target; 
    const re = /^[a-zA-Z\s]*$/g;
    if (name=='first_name' && (value === "" || re.test(value))) {      
      setData((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
    }else if(name=='pincode'){
      let pincodevalue = e.target.value.replace(/\D/g, "");
      setData((prevState) => ({ ...prevState, [e.target.name]: pincodevalue }));
    }else if(name=='mobile_no'){
      let mobileVal = e.target.value.replace(/\D/g, "");
      setData((prevState) => ({ ...prevState, [e.target.name]: mobileVal }));
    }
    else if(name!='first_name'){
      setData((prevState) => ({ ...prevState, [name]: value }));
    }
    
  };

  const updateTermsCheckbox = (event) => {
    if (event.target.checked) {
      data.terms = true;
      setData({ ...data });
    } else {
      data.terms = false;
      setData({ ...data });
    }
    setIsChecked((current) => !current);
  };

  let genrateNumberUUId = () => {
    const length = 15;
    const chars =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var result = "";
    for (var i = length; i > 0; --i)
      result += chars[Math.round(Math.random() * (chars.length - 1))];

    sessionStorage.setItem("linkId", result);
    return result;
  };

  let cleanObj =(obj) => {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName]=='') {
        delete obj[propName];
      }
    }
    return obj
  }


  let saveFieldDataonBlurEvent = () =>{
    let savedataObj  = {
      "order_id"      : sessionStorage.getItem("lead_id"),
      "email"         : data.email,
      "customer_name" : data.first_name,
      "flat_no"       : data.flat,
      "block_or_street"  : data.block,
      "locality_non_master"  : data.locality,
      "city"          : data.city,
      "pin_code"      : data.pincode,
      "initiator"     : "acquisition_journey_info_update"} ; 

     let finalObj = cleanObj(savedataObj);

    UserService.addDeliveryAdress(finalObj).then((response) =>{
              
    })
  }

  const renderMygateData =(mygateData)=>{
    data.email = (mygateData.email) ? mygateData.email : '';
    data.first_name = ((mygateData.firstName) ? mygateData.firstName + ' ' : '') + ((mygateData.lastName) ? mygateData.lastName : '');
    data.flat = (mygateData.flatNo) ? mygateData.flatNo : '';
    data.block = (mygateData.floor) ? mygateData.floor : '';
    data.locality = (mygateData.otherSociety) ? mygateData.otherSociety : '';
    data.city = (mygateData.city) ? mygateData.city : '';
    data.pincode = (mygateData.pinCode) ? mygateData.pinCode : '';
    data.terms = true;
    setIsChecked(true);
//    console.log("isChecked == ", isChecked);
    setData({ ...data });

  }
  const checkEmailExist =(e)=>{
    e.preventDefault();
    let schemeId = sessionStorage.getItem("stg_scheme_id"); 
    setFormErrors({});
    let enterMobile = e.target.value;  
    if(!e.target.value) {
      setFormErrors({mobile_no:"This field is required."});
    }else if(e.target.value!=" " && e.target.value.length < 10){      
      setFormErrors({mobile_no:"Mobile No. should not less then 10 digit"})
    }else{
      UserService.generateOtp(data.mobile_no, "SSP", utmSource, null, null, true).then((response) => {
        if (response && response.success==true) {
          setFormErrors({})
          setMobileError(
              <>
                You are already an existing reader with us. Please {' '}
                <a href={baseUrl}>click here</a> to login to your account.
              </>
            );
        }else{
            setMobileError("");
            if(utmSource === 'mygate' && response.data){
                   renderMygateData(response.data);
                   let leadId = response.data.id;
                   sessionStorage.setItem("lead_id",leadId);
                    saveFieldDataonBlurEvent();
            }else{
              if(sessionStorage.getItem("stg_mobile_number")==undefined || sessionStorage.getItem("stg_mobile_number")!=enterMobile){
                sessionStorage.setItem("stg_mobile_number", data.mobile_no);
                UserService.addNewLeadGenerate(data.mobile_no,schemeId,utmSource).then((response) => {
                    if(response.data.success){
                    let leadId = response.data.lead_id;
                    sessionStorage.setItem("lead_id",leadId);
                    }
                })
              }
            }
        }
      }).catch((err) => {
    }).catch((err) => {     
      }).catch((err) => {     
        setMobileError("");
      })
  }
  }

  const saveEmail = (e) =>{
    e.preventDefault();
    setFormErrors({});
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    let emailId = e.target.value;
    if (!emailId) {
      setFormErrors({email : "This field is required."});
    } else if (e.emailId!= "" && !emailRegex.test(emailId)) {
      setFormErrors({email :"This is not a valid email format."});
    }else{
      if(sessionStorage.getItem("lead_id")!=undefined){     
        saveFieldDataonBlurEvent();
      }
    }
  }

  const saveName = (e) =>{
    e.preventDefault();
    setFormErrors({});
    let username = e.target.value;
    if (!username) {
      setFormErrors({first_name : "This field is required."});
    }else{
      if(sessionStorage.getItem("lead_id")!=undefined){       
        saveFieldDataonBlurEvent();
      }
    }
  }

  const saveFlatNo = (e) =>{
    e.preventDefault();
    setFormErrors({});
    let flat_no = e.target.value;
    if (!flat_no) {
      setFormErrors({flat : "This field is required."});
    }else if (flat_no!=undefined && flat_no.length >10 ) {
      setFormErrors({flat : "Flat no should not greater then 10 character."});
    }else{    
      if(sessionStorage.getItem("lead_id")!=undefined){       
        saveFieldDataonBlurEvent();
      }
    }
  }

  const saveBlockStreet = (e) =>{
    e.preventDefault();
    setFormErrors({});
    let blockStreet = e.target.value;
    if (!blockStreet) {
      setFormErrors({block : "This field is required."});
    }else {
      if(sessionStorage.getItem("lead_id")!=undefined){        
        saveFieldDataonBlurEvent();
      }
    }
  }

  const savelocality = (e) =>{
    e.preventDefault();
    setFormErrors({});
    let locality = e.target.value;
    if (!locality) {
      setFormErrors({locality : "This field is required."});
    }else{
      if(sessionStorage.getItem("lead_id")!=undefined){        
        saveFieldDataonBlurEvent();
     }
    }
  }

  const savePincode = (e) =>{
    e.preventDefault();
    setFormErrors({});
    let pincodeval = e.target.value;
    if (!pincodeval) {
      setFormErrors({pincode : "This field is required."});
    }else if (pincodeval != "" && pincodeval.length < 6) {
      setFormErrors({pincode : "Pincode must be 6 digit."});
    }else{ 
      if(sessionStorage.getItem("lead_id")!=undefined){       
        saveFieldDataonBlurEvent();
      }
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormErrors({});
    let errorObj =  validate(data);
     if(data.mobile_no!=''){
      let response = await UserService.generateOtp(data.mobile_no, "SSP", utmSource, null, null, true);
      if (response && response.success==true) {
        setMobileError("");
        errorObj.mobile_no = (<span>
                                     You are already an existing reader with us. Please
                                     <a href={baseUrl}>click here </a>
                                     to login to your account.
                                   </span>
                                 );
      }
     }
      
    if (Object.values(errorObj).length > 0) {
      setFormErrors(errorObj);
    } else {
      sessionStorage.removeItem("flat_no");
      sessionStorage.removeItem("block_or_street");
      sessionStorage.removeItem("locality");
      sessionStorage.removeItem("city");
      sessionStorage.removeItem("pin_code");
      sessionStorage.removeItem("email");
      sessionStorage.removeItem("customer_name");
      sessionStorage.removeItem("stg_mobile_number");

      sessionStorage.setItem("flat_no", data.flat);
      sessionStorage.setItem("block_or_street", data.block);
      sessionStorage.setItem("locality", data.locality);
      sessionStorage.setItem("city", data.city);
      sessionStorage.setItem("state", data.city);
      sessionStorage.setItem("pin_code", data.pincode);
      sessionStorage.setItem("email", data.email);
      sessionStorage.setItem("customer_name", data.first_name);
      sessionStorage.setItem("stg_mobile_number", data.mobile_no);
      sessionStorage.setItem("stg_emailAdress_Checkout_updated", data.email);

      let RandomUuid = genrateNumberUUId();

      moengageCheckoutBtn_Clicked();

      if(sessionStorage.getItem("lead_id")!=undefined){     
        saveFieldDataonBlurEvent();
      }


      // Payment Transaction API //
      UserService.paymentTransaction(
        RandomUuid,
        orderData?.planPrice ?? sessionStorage.getItem("moe_acq_selected_planPrice"),
        data.mobile_no,
        orderData?.planId ?? sessionStorage.getItem("stg_scheme_id"),
        "fresh_" + data.mobile_no,
        gateway,
        data.email,
        "",
        sessionStorage.getItem("lead_id")
      )
        .then((response) => {
          if (response.success) {
            setTransToken(response.txnToken);
            setMid(response.mid);
            sessionStorage.setItem("mId", response.mid);
            sessionStorage.setItem("stg_leadId", response.lead_id);
            sessionStorage.removeItem("stg_mobile_number")
            setPaytmPageShow(true);
          } else {
            //this.setState({ loading: false });
          }
        })
        .catch((err) => {
          //this.defultAlert("Failed! Check your network")
          //this.setState({ loading: false });
          sessionStorage.removeItem("stg_mobile_number")
        });
    }
  };
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

  const validate =  (values) => {
    const errors = {};
    if (!values.mobile_no) {
      errors.mobile_no = "This field is required.";
    }else if(values.mobile_no!='' && values.mobile_no.length < 10){
      errors.mobile_no = "Mobile No. should not less then 10 digit";
    }
    if (!values.email) {
      errors.email = "This field is required.";
    } else if (values.email != "" && !emailRegex.test(values.email)) {
      errors.email = "This is not a valid email format.";
    }

    if (!values.first_name) {
      errors.first_name = "This field is required.";
    }
    if (!values.flat) {
      errors.flat = "This field is required.";
    }
    if (values.flat!=undefined && values.flat.length >10 ) {
      errors.flat = "Flat no should not greater then 10 character.";
    }
    if (!values.block) {
      errors.block = "This field is required.";
    }
    if (values.block!=undefined && values.block.length >50) {
      errors.block = "Block/street no should not greater then 50 character.";
    }
    if (!values.locality) {
      errors.locality = "This field is required.";
    }
    if (values.locality!=undefined && values.locality.length >50 ){
      errors.locality = "Locality should not greater then 50 character.";
    }
    if (!values.city) {
      errors.city = "This field is required.";
    }
    if (!values.pincode) {
      errors.pincode = "This field is required.";
    }
    if (values.pincode != "" && values.pincode.length < 6) {
      errors.pincode = "Pincode must be 6 digit.";
    }

    if (!values.terms) {
      errors.termsmsg = "Please accept terms and conditions";
      setTermsErrmsg(true);
    } else {
      setTermsErrmsg(false);
    }
    return errors;
  };

  const redirectToHome = () => {
    if (sessionStorage.getItem("createNewAccountActive") === "ON") {
      moengage_Acq_CheckoutBackBtn_Clicked();      
      sessionStorage.removeItem("stg_mobile_number");
      window.location = "/newCustomer";
    } else {
      window.location = "/";
    }
  };

  const showTermsPopup = () => {
    setShowPopupAlertState(true);
  };

  const closeTermsPopup = () => {
    setShowPopupAlertState(false);
  };

  const onDismissRazorpay =()=> {
    setPaytmPageShow(false);
   }

  return (
    <div className="container">
      
      <div className="row justify-content-center mt-4">
        
        <div className="col-12 col-md-12 px-4">
         
          <div className="ht_plan_summery" id="hide">
            <div className="plan_header_box">
              <div className="nws_paper_title">
                <div className="back-btn-pincode">
                  <FontAwesomeIcon icon={faArrowLeft} size="2x" className="faArrowLeft" onClick={redirectToHome} />
                </div>
                <div className="feature">
                  <span className="p_title">
                    {/* {pubFullName[orderData.planName]} */}
                    {pubFullName[sessionStorage.getItem("stg_selectedPlan_Name")]}
                  </span>
                  <span className="year_title">
                    {/* {orderData.planDuration > 0 && orderData.planDuration + " Year"} */}
                    {sessionStorage.getItem("moe_acq_selected_yearPlan") > 0 && sessionStorage.getItem("moe_acq_selected_yearPlan") + " Year Plan"}
                  </span>
                </div>
              </div>
              <div className="nws_paper_price_sec">
                <span className="last_price">
                  <strike>
                    {/* {orderData.coverPrice > 0 && "₹"}
                    {orderData.coverPrice > 0 && orderData.coverPrice} */}
                    {sessionStorage.getItem("moe_acq_selected_coverPrice") > 0 && "₹"}
                    {sessionStorage.getItem("moe_acq_selected_coverPrice") > 0 && sessionStorage.getItem("moe_acq_selected_coverPrice")}
                  </strike>
                </span>
                {/* <span className="orgnl_price">₹{orderData.planPrice}</span> */}
                <span className="orgnl_price">₹{sessionStorage.getItem("moe_acq_selected_planPrice")}</span>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div className="row summery_plan_row">
        <div className="col-12 col-md-12 px-5">
          <div className="contact_plan_summery px-2">
            <div className="header_title">
              <img src={contactIcon} className="mr-3" />
              <span>Contact Information</span>
            </div>
            <div className="contact_header_box">
              <div className="cntct_title">
                <form className="cntct_fields">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="input-style">
                        <label htmlFor="mobile">
                          Mobile No <span>*</span>
                        </label>
                        <input
                          type="text"
                          className="order-input"
                          name="mobile_no"
                          value={data.mobile_no || ""}
                          onChange={handleChange}
                          onBlur={checkEmailExist}
                          placeholder="Enter Mobile No"
                          id="mobile"
                          autoComplete="off"
                          maxLength={10}
                          onInput={(e) => {
                            if (e.target.value.length > e.target.maxLength)
                              e.target.value = e.target.value.slice(
                                0,
                                e.target.maxLength
                              );
                          }}
                        />
                        <span className="text-danger">{formErrors.mobile_no}</span>
                        <span className="text-danger">{mobileError}</span>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="input-style">
                        <label htmlFor="email">
                          Email Id <span>*</span>
                        </label>
                        <input
                          type="text"
                          id="email"
                          name="email"                      
                          placeholder="Enter email "
                          value={data.email || ""}
                          onChange={handleChange}
                          onBlur={saveEmail}
                        />
                        <span className="text-danger">{formErrors.email}</span>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row summery_plan_row">
        <div className="col-md-12 px-5">
          <div className="address_plan_summery px-2">
            <div className="header_title">
              <img src={addressBook} className="mr-3" />
              <span>Shipping Address</span>
            </div>
            <div className="address_header_box">
              <div className="adres_title">
                <form className="address_fields">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className="input-style">
                      <label htmlFor="fname">
                        Name <span>*</span>
                      </label>
                      <input
                        type="text"
                        id="fname"
                        name="first_name"
                        value={data.first_name || ""}
                        onChange={handleChange}
                        onBlur={saveName}
                        placeholder="Enter Name"                     
                      />
                      <span className="text-danger">
                        
                        {formErrors.first_name}
                      </span>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="input-style">
                      <label htmlFor="flat">
                        Flat/House No/Floor No.
                        <span>*</span>
                      </label>
                      <input
                        type="text"
                        id="flat"
                        name="flat"
                        value={data.flat || ""}
                        maxLength={9}
                        onChange={handleChange}
                        onBlur={saveFlatNo}
                        placeholder="Enter Flat/House No"
                      />
                      <span className="text-danger">{formErrors.flat}</span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className="input-style">
                      <label htmlFor="block">
                        Block/Street/Plot No.
                        <span>*</span>
                      </label>
                      <input
                        type="text"
                        id="block"
                        name="block"
                        maxLength={50}
                        value={data.block || ""}
                        onChange={handleChange}
                        onBlur={saveBlockStreet}
                        placeholder="Enter Block/Street No"
                      />
                      <span className="text-danger">{formErrors.block}</span>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="input-style">
                      <label htmlFor="locality">
                        Locality <span>*</span>
                      </label>
                      <input
                        type="text"
                        id="locality"
                        name="locality"
                        maxLength={50}
                        value={data.locality || ""}
                        onChange={handleChange}
                        onBlur={savelocality}
                        placeholder="Enter Locality"
                      />
                      <span className="text-danger">{formErrors.locality}</span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6">
                      <div className="input-style">
                        <label htmlFor="city">
                          City <span>*</span>
                        </label>
                        <input
                          type="text"
                          id="city"
                          name="city"
                          readOnly={true}
                          disabled={true}
                          value={data.city || ""}
                          onChange={handleChange}
                        />
                        <span className="text-danger">{formErrors.city}</span>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="input-style">
                        <label htmlFor="pincode">
                          Pincode <span>*</span>
                        </label>
                        {pincodeGiven? 
                          (
                            <input
                            type="text"
                            id={data.pincode}
                            name="preshowpincode"
                            maxLength={6}
                            value={data.pincode}
                            readOnly
                          />
                          ):
                          (
                          <input
                          type="text"
                          id={data.pincode}
                          name="pincode"
                          maxLength={6}
                          value={data.pincode || ""}
                          onChange={handleChange}
                          onBlur={savePincode}
                          placeholder="Enter Pincode"
                          onInput={(e) => {
                            if (e.target.value.length > e.target.maxLength)
                              e.target.value = e.target.value.slice(
                                0,
                                e.target.maxLength
                              );
                          }}
                        />
                          )
                        
                        }
                      <span className="text-danger">{formErrors.pincode}</span>
                      </div>
                    </div>
                </div>
                <div className="terms mb-3 blackClourV2">
                    <input
                      type="checkbox"
                      name="terms"
                      id="terms"
                      value={isChecked}
                      checked={isChecked}
                      onChange={updateTermsCheckbox}
                    />
                    <label for="terms" className="terms-label ml-2">
                      <span>I agree to </span>
                      <a href="javascript: void(0)" onClick={showTermsPopup}> Terms &amp; Conditions</a>
                    </label>
                    {termsErrmsg && (
                      <span className="text-danger">
                        {"Please agree terms & conditions"}
                      </span>
                    )}
                    
                  </div>
                  <div className="address_btn mt-1 mb-3 text-center">
                    <button
                      className="btn checkout-btn"
                      value={data.terms}
                      onClick={handleSubmit}
                    >
                      CHECKOUT
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {paytmPageShow &&
        gateway &&
        (gateway === "Paytm" ? (
          <ShowPaytmPaymentPage txnToken={transToken} />
        ) : (
          <ShowRazorPayPaymentPage
            txnToken={transToken}
            mobile={data.mobile_no}
            email={data.email}
            name={data.first_name}
            price={orderData?.planPrice ?? sessionStorage.getItem("moe_acq_selected_planPrice")}
            onDismissRazorpay={onDismissRazorpay}
          />
        ))}
          <TermsPopup
          showModalPopup={showPopupAlertState}
          onPopupClose={closeTermsPopup}
        ></TermsPopup>
    </div>
  );
}

export default Order;
