import React, {useState, useEffect} from 'react'
import PreAuthBackground from '../pages/PreAuthBackground'
import UserService from '../services/UserService'
import PreAuthAllSchemes from './PreAuthAllSchemes'
import '../css/StyleV2.css'
import MobileLoginNJ from './MobileLoginNJ'

import PageLoader from '../subComponents/PageLoader';

const PreAuth =(props) =>
{
    const [isLoading, setLoading] = useState(true);
    const [preAuthScreen, setPreAuthScreen] = useState(false);
    const [loginPage, setLoginPage] = useState(false);
    const [prauToknen, setPrauTokne] = useState("");
    const [prauUserName, setPrauUserName] = useState("");

    let count=0;
    sessionStorage.removeItem("showHeader");
    sessionStorage.setItem("showHeader","OFF")

    useEffect(() => 
    {
        // debugger
        let preAuthRedirectLink,preAuthRedToken,userName;

        if(count===0)
        {
        setTimeout(async ()=> 
        {
            sessionStorage.removeItem("preAuthAllSchemeEndpoint");
            sessionStorage.removeItem("preAuthAllSchemeToken");
            UserService.verifyPreAuthRedirection(props.unique_code).then(response => 
                {

                if (response.data !== "") 
                {
           
                    preAuthRedirectLink = response.data.redirect_url           
                    if(preAuthRedirectLink.length>1)
                    {
                        preAuthRedToken= response.data.token   
                        userName=response.data.customerName
                        setPrauTokne(preAuthRedToken)
                        setPrauUserName(userName)
                        sessionStorage.setItem("preAuthAllSchemeEndpoint",preAuthRedirectLink);
                        sessionStorage.setItem("orderId", props.unique_code)
                        sessionStorage.setItem("preAuth", "ON");
                        sessionStorage.setItem("orderDetails", JSON.stringify(response.data));
                        sessionStorage.setItem("stg_preAuth_orderDetails", JSON.stringify(response.data));

                        sessionStorage.setItem("preAuth_email", response.data.email);
                        sessionStorage.setItem("preAuth_mobile", response.data.mobile);
                        sessionStorage.setItem("preAuth_customerName", response.data.customerName);
                        setLoading(false)
                        setPreAuthScreen(true)
                    }
                    else
                    {
                        setLoading(false)
                        setPreAuthScreen(false)
                        setLoginPage(true)
                    }
                }
                }).catch(err => 
                {
                    console.log(" App_L err",err)
                    console.log(" App_L ->A1-failed-->verifyPreAuthRedirection")
                })
                count++
        
        },800)    
         
        }
    },[]);

    if(isLoading===true)
    {
    return(
        <div> 
            <PreAuthBackground/>
                <PageLoader/>
        </div>
    )
    }
    else
    {
        return(
            <div>
                {preAuthScreen ? <PreAuthAllSchemes preAuthToken={prauToknen} preUserName={prauUserName}/> : <MobileLoginNJ/>}
                </div>
        )
    }   
}

export default PreAuth;