import ReactGA from "react-ga";

export const createGAEventTracker = (category = "Category") => {
    return (action = "action", label = "label") => {
        ReactGA.event({
            category,
            action,
            label,
        });
    };
};
