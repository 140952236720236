import React, { Component } from 'react';
import sidebarImg from '../images/sidebar-img.png';
import mainLogo from '../images/mainLogo.png';
import '@fontsource/poppins';
import '../css/Style.css';
 


class LoginSidebar extends Component 
{

    redirectToHome() {
      window.location.href = window.location.origin;
    }

    render() {
      return (
        <div className='login-sidebar-main'>
          <div className="vertical-text">
            <p className="subscribenowtitle">
                <b>Subscribe now!</b> To get credible news at incredible price!
            </p>
          </div>
          <div className='mainlogo-home'>
            <a className="navbar-brand-1" target="_blank">
              <img src={mainLogo} onClick={this.redirectToHome} alt="HT Logo"/></a>  
          </div>
          {/* <img className='div-left-img' src={sidebarImg} alt="First slide" /> */}
        </div>
      )
    }
} 
export default LoginSidebar;