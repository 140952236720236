import React, { Component, Fragment } from 'react';  
import { Modal } from 'react-bootstrap';  
import mainLogo from './../images/mainLogo.png';

import './../css/Checkout.css';

class Tnc extends Component {  
    constructor(props) {  
        super(props);  
        this.state = {  
            showModal: false,
            paymentOptions: []
        };  
    }  
    
   componentDidMount() 
   {
   }
  
    isShowModal = (status) => {  
        this.handleClose();  
        this.setState({ showModal: status });  
    }  
  
    handleClose = () => { 
        sessionStorage.setItem("stg_mo_redirectFrom","Close T&C Pop_up")
        this.props.onPopupClose(false);  
    }  


    render() 
    {  
        return (  
            <Fragment>  
                <Modal show={this.props.showModalPopup} onHide={this.handleClose}  
                    size="lg"  
                    aria-labelledby="contained-modal-title-vcenter"  
                    centered  
                >  
                    <Modal.Header closeButton>  
                        <Modal.Title id="sign-in-title" className="blackClourV2">  
                            <h4 className="text-center">{"Terms & Conditions "}</h4>
                         </Modal.Title>  
                    </Modal.Header>  
                    <Modal.Body>  
                    <div>
                <section className="position-relative login-page">
		<div id="home" className="">
        <nav className="navbar navbar-expand-lg navbar-light bg-grey-mobile bg-grey">
              <a > 
                <img src={mainLogo} className="img-fluid" alt="main Logo" />
              </a>
              <p className="nav-item ansp ml-auto mb-0 blackClourV2">
                <span className="blackClourV2"><u className="blackClourV2">Customer Care</u><br></br> <i className="fa fa-phone" aria-hidden="true"></i> Delhi- 01160004242 <br></br> <i className="fa fa-phone" aria-hidden="true"></i> Mumbai- 02267764242</span>
              </p>
            </nav>
			  
		</div>
	</section> 
	<div className="container t-and-c">
		<div className="row">
			<div className="col-md-12">
				 
				<div className="mt-5 blackClourV2">
					<h4 className="text-center"><strong> UPTO Rs 250 Cash back</strong></h4>
					
					<h5 className="mt-lg-5 mt-3 mb-3 mb-lg-0">Detailed T&C:- </h5>
					
					<ul>
						<p className="mt-lg-4 mb-0"> <strong>Offer Eligibility </strong></p>
						<li>This offer is applicable only when you Pay HT Media Ltd. for ₹899 or more using Paytm wallet</li>
						<li>Offer can be availed 1 time during the campaign validity</li>
						<li>Offer is applicable for selected users only</li>

					</ul>

					
					<ul>
						<p className="mt-lg-3 mb-0"> <strong>Offer Duration</strong></p>
						<li>On your first transaction, the offer will be automatically activated.</li>
						<li>Pay HT Media Ltd. 1 time before 22-10-2020, 23:59:00 to earn upto ₹250 cashback</li>
					</ul>

					<ul>
						<p className="mt-lg-3 mb-0"> <strong>Rewards</strong></p>
						<li> Each reward will be received within 30 days of completion of the transaction on which the reward is eligible.</li>
						<li>All scratch cards issued from this offer will expire after 10 days</li>
						<li>If you have not completed your KYC, or have breached your monthly wallet limit, instead of cashback, you will receive cashback in Gift Voucher</li>
						<li>Paytm reserves its absolute right to withdraw and/or alter any terms and conditions of the offer at any time without prior notice.</li>
					</ul>
				</div>
			</div>
		</div>
   </div>
            </div>
                        
                    </Modal.Body>  
                </Modal >  
            </Fragment > 
        );  
    }  
}  
  
export default (Tnc); 