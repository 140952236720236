import React, { Component, createRef } from 'react'
import './../css/Style.css';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import UserService from '../services/UserService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import AsyncSelect from 'react-select/async'

export default class ChangeAddressDetails extends React.Component {


  msg = (str) => toast.info(str, {
      position: toast.POSITION.TOP_CENTER
      // autoClose: 2000
  });
  constructor(props) {
      super(props)
      this.state = {

          locationCode: "",
          localityList: [],
          localityNameList: [],
          
          selectValue: "",
          selectValueSociety: "",
          flatNumber: "",
          addLocalityName: "",
          addSocityName:"",
          addCityName:"",
          addPinCodeNumber:"",
          addStateName:"",
          street: "",
          floor: "",
          pincode: "",
          state: "",
          city: "",
          societyList: [],
          mandatoryIcon: true,
          searchTerm:"enter 3 characters to search a new locality",
          localityId: "",
          localityName: "",
          societyId: "",
          submitButton: false,
          submitDisabled: true,
          localityIteration:0,
          flatNumberSubmit: "",
          floorSubmit:"",
          streetSubmit:"",
          localityIdSubmit: "",
          societyIdSubmit:"",
          flatNum:"",
          addLocalityNum:"",
          addSocityNum:"",
          addCityNum:"",
          addPincodeNum:"",
          addStateNum:"",
          isSocityNotFound: true

      }
      
      this.handleKeyUp = this.handleKeyUp.bind(this);
      this.handleChangeLocality = this.handleChangeLocality.bind(this);
      this.getAllSocieties = this.getAllSocieties.bind(this);
      this.handleChangeSociety = this.handleChangeSociety.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.changeFlatHandler = this.changeFlatHandler.bind(this);
      this.changeStreetHandler = this.changeStreetHandler.bind(this);
      this.changeFloorHandler = this.changeFloorHandler.bind(this);
      this.changePincodeHandler = this.changePincodeHandler.bind(this);
      // this.changeLocalityHandler = this.changeLocalityHandler.bind(this);
  }
  moengageEditAddress_Clicked()
  {
    
    if(process.env.REACT_APP_ENABLE_MOENGAGE==="true")
    {
              
      if(sessionStorage.getItem("createNewAccountActive")==="ON")
      {
          window.Moengage.track_event( "Acquisition Edit Address Icon Clicked", 
          {
             Mobile_Number: sessionStorage.getItem("stg_mobile_number"),Campaign_Source: sessionStorage.getItem("stg_utm_source"), 
             Campaign_Medium: sessionStorage.getItem("stg_utm_medium"), Campaign_Name: sessionStorage.getItem("stg_utm_name"),
             Plan_Name: sessionStorage.getItem("stg_selectedPlan_Name"), Plan_Duration: sessionStorage.getItem("stg_selectedPlan_Duration"),
             Plan_Price : sessionStorage.getItem("stg_selectedPlan_Price"), Email : sessionStorage.getItem("stg_emailAdress_Checkout_updated"),
             Existing_address : sessionStorage.getItem("stg_existing_address_DeliverPage_updated"),Redirect_From : sessionStorage.getItem("stg_mo_redirectFrom"),
             Mobile_Number : sessionStorage.getItem("acqPhoneNumber"),Registered: sessionStorage.getItem("stg_acq_userType")
          }
         )
      }
      else
      {   
          window.Moengage.track_event( "Edit Address Icon Clicked", 
          {
             Mobile_Number: sessionStorage.getItem("stg_mobile_number"),Campaign_Source: sessionStorage.getItem("stg_utm_source"), 
             Campaign_Medium: sessionStorage.getItem("stg_utm_medium"), Campaign_Name: sessionStorage.getItem("stg_utm_name"),
             Plan_Name: sessionStorage.getItem("stg_selectedPlan_Name"), Plan_Duration: sessionStorage.getItem("stg_selectedPlan_Duration"),
             Plan_Price : sessionStorage.getItem("stg_selectedPlan_Price"), Email : sessionStorage.getItem("stg_emailAdress_Checkout_updated"),
             Existing_address : sessionStorage.getItem("stg_existing_address_DeliverPage_updated"),Redirect_From : sessionStorage.getItem("stg_mo_redirectFrom"),
             Mobile_Number : sessionStorage.getItem("acqPhoneNumber"),Registered: sessionStorage.getItem("stg_acq_userType")
          }
         )
      }
    }
  }

  moengageAddressSubmit_Clicked()
  {
    
    if(process.env.REACT_APP_ENABLE_MOENGAGE==="true")
    {
          
      if(sessionStorage.getItem("createNewAccountActive")==="ON")
      {
          window.Moengage.track_event( "Acquisition Delivery Address Submitted", 
          {
             Mobile_Number: sessionStorage.getItem("stg_mobile_number"),Campaign_Source: sessionStorage.getItem("stg_utm_source"), 
             Campaign_Medium: sessionStorage.getItem("stg_utm_medium"), Campaign_Name: sessionStorage.getItem("stg_utm_name"),
             Plan_Name: sessionStorage.getItem("stg_selectedPlan_Name"), Plan_Duration: sessionStorage.getItem("stg_selectedPlan_Duration"),
             Plan_Price : sessionStorage.getItem("stg_selectedPlan_Price"), Email : sessionStorage.getItem("stg_emailAdress_Checkout_updated"),
             Existing_address : sessionStorage.getItem("stg_existing_address_DeliverPage_updated"),Redirect_From : sessionStorage.getItem("stg_mo_redirectFrom"),
             Updated_Address : sessionStorage.getItem("stg_new_address_DeliverPage_updated"),Redirect_From : sessionStorage.getItem("stg_mo_redirectFrom"),
             Mobile_Number : sessionStorage.getItem("acqPhoneNumber"),Registered: sessionStorage.getItem("stg_acq_userType")
          }
         )
      }
      else
      {
          window.Moengage.track_event( "Submit Button Clicked", 
          {
             Mobile_Number: sessionStorage.getItem("stg_mobile_number"),Campaign_Source: sessionStorage.getItem("stg_utm_source"), 
             Campaign_Medium: sessionStorage.getItem("stg_utm_medium"), Campaign_Name: sessionStorage.getItem("stg_utm_name"),
             Plan_Name: sessionStorage.getItem("stg_selectedPlan_Name"), Plan_Duration: sessionStorage.getItem("stg_selectedPlan_Duration"),
             Plan_Price : sessionStorage.getItem("stg_selectedPlan_Price"), Email : sessionStorage.getItem("stg_emailAdress_Checkout_updated"),
             Existing_address : sessionStorage.getItem("stg_existing_address_DeliverPage_updated"),Redirect_From : sessionStorage.getItem("stg_mo_redirectFrom"),
             Updated_Address : sessionStorage.getItem("stg_new_address_DeliverPage_updated"),Redirect_From : sessionStorage.getItem("stg_mo_redirectFrom"),
             Mobile_Number : sessionStorage.getItem("acqPhoneNumber"),Registered: sessionStorage.getItem("stg_acq_userType")
          }
         )
      }

     

    }
  }
  
  componentDidMount() 
  {
      this.setState({submitDisabled:true })
      this.getAllSocieties(this.state.localityId)
      window.addEventListener('keyup', this.handleKeyUp, false); 

      if(sessionStorage.getItem("stg_loggedInHeader")|| sessionStorage.getItem("stg_acq_exitingUser") === "ON" || sessionStorage.getItem("preAuth") ==="ON")
      {
          this.setState({locationCode: JSON.parse(sessionStorage.getItem('orderDetails')).locationCode })
          this.setState({selectValue: JSON.parse(sessionStorage.getItem('orderDetails')).addressDetails.localityName })
          this.setState({flatNumber: JSON.parse(sessionStorage.getItem('orderDetails')).addressDetails.flatNum || "NA" })
          this.setState({street: JSON.parse(sessionStorage.getItem('orderDetails')).addressDetails.blockOrStreet || "NA" })
          this.setState({floor: JSON.parse(sessionStorage.getItem('orderDetails')).addressDetails.floor || "NA"})

          this.setState({pincode: JSON.parse(sessionStorage.getItem('orderDetails')).addressDetails.pincode || "NA" })
          this.setState({state: JSON.parse(sessionStorage.getItem('orderDetails')).addressDetails.state })
          this.setState({city: JSON.parse(sessionStorage.getItem('orderDetails')).addressDetails.city || "NA" })
          this.setState({localityId: JSON.parse(sessionStorage.getItem('orderDetails')).addressDetails.localityId })
          this.setState({localityName: JSON.parse(sessionStorage.getItem('orderDetails')).addressDetails.localityName})

          this.setState({societyId: JSON.parse(sessionStorage.getItem('orderDetails')).addressDetails.societyId })
          this.setState({flatNumberSubmit: JSON.parse(sessionStorage.getItem('orderDetails')).addressDetails.flatNum || "NA" })
          this.setState({floorSubmit: JSON.parse(sessionStorage.getItem('orderDetails')).addressDetails.floor || "NA"})
          this.setState({localityIdSubmit: JSON.parse(sessionStorage.getItem('orderDetails')).addressDetails.localityId })
          this.setState({streetSubmit: JSON.parse(sessionStorage.getItem('orderDetails')).addressDetails.blockOrStreet || "NA" })
          this.setState({societyIdSubmit: JSON.parse(sessionStorage.getItem('orderDetails')).addressDetails.societyId})

      }

      if(sessionStorage.getItem('stg_nda_adressDetails')!==null && sessionStorage.getItem("stg_acq_freshUser")==="ON")
      {

          if(sessionStorage.getItem("stg_city")==="CPM")
          {
              this.setState({addCityName:"Chandigarh, Panchkula & Mohali"})
              this.setState({addStateName: "Punjab" })

          }
          else if(sessionStorage.getItem("stg_city")==="Kanpur")
          {
              this.setState({addCityName:"Kanpur"})
              this.setState({addStateName: "Uttar Pradesh" })
          }
          else if(sessionStorage.getItem("stg_city")==="Mumbai")
          {
              this.setState({addCityName:"Mumbai"})
              this.setState({addStateName: "Maharashtra" })
          }
          else if(sessionStorage.getItem("stg_city")==="Delhi")
          {
              this.setState({addCityName:"Delhi"})
              this.setState({addStateName: "Delhi" })
          }
          this.setState({submitDisabled:false })
          this.setState({flatNumber:JSON.parse(sessionStorage.getItem('stg_nda_adressDetails')).flat_no})
          this.setState({floor:JSON.parse(sessionStorage.getItem('stg_nda_adressDetails')).floor})
          this.setState({street: JSON.parse(sessionStorage.getItem('stg_nda_adressDetails')).block_or_street })
          this.setState({addPinCodeNumber: JSON.parse(sessionStorage.getItem('stg_nda_adressDetails')).pin_code })
          this.setState({addLocalityName: JSON.parse(sessionStorage.getItem('stg_nda_adressDetails')).locality})
          this.setState({addSocityName: JSON.parse(sessionStorage.getItem('stg_nda_adressDetails')).society})
      }
      if(sessionStorage.getItem("stg_acq_freshUser")==="ON")
      {

          if(sessionStorage.getItem("stg_city")==="CPM")
          {
              this.setState({addCityName:"Chandigarh, Panchkula & Mohali"})
              this.setState({addStateName: "Punjab" })

          }
          else if(sessionStorage.getItem("stg_city")==="Kanpur")
          {
              this.setState({addCityName:"Kanpur"})
              this.setState({addStateName: "Uttar Pradesh" })
          }
          else if(sessionStorage.getItem("stg_city")==="Mumbai")
          {
              this.setState({addCityName:"Mumbai"})
              this.setState({addStateName: "Maharashtra" })
          }
          else if(sessionStorage.getItem("stg_city")==="Delhi")
          {
              this.setState({addCityName:"Delhi"})
              this.setState({addStateName: "Delhi" })
          }
      }
     
      this.moengageEditAddress_Clicked();

  }

  handleKeyUp(e) {

      const { closeaddressChange } = this.props;
      const keys = {
          27: () => 
          {
              e.preventDefault();
              closeaddressChange();
              window.removeEventListener('keyup', this.handleKeyUp, false);
          },
      };

      if (keys[e.keyCode]) { keys[e.keyCode](); }
  }

  componentWillUnmount() {
      window.removeEventListener('keyup', this.handleKeyUp, false);
  }

  changeFlatHandler=async event =>
  {
      await this.setState({ flatNumber: event.target.value });

      if(sessionStorage.getItem("stg_loggedInHeader") || sessionStorage.getItem("stg_acq_exitingUser") === "ON" || sessionStorage.getItem("preAuth") ==="ON")
      {       
          if(this.state.flatNumberSubmit!=this.state.flatNumber)
          {

              this.setState({submitDisabled : false})
          }
          else if(this.state.flatNumberSubmit===this.state.flatNumber)
          {
              this.setState({submitDisabled : true})
          }
      }
      else
      {
          this.verifySubmitDisabled();
      }
  }

  changeFloorHandler=async event =>
  {
      // debugger
      await this.setState({ floor: event.target.value })
      // if(sessionStorage.getItem("stg_loggedInHeader") || sessionStorage.getItem("stg_acq_exitingUser") === "ON")
      // {
      //     if(this.state.floorSubmit!=this.state.floor)
      //         {
      //             this.setState({submitDisabled : false})
      //         }
      //         else if(this.state.floorSubmit===this.state.floor)
      //         {
      //             this.setState({submitDisabled : true})
      //         }
      // }
      // else
      // {
      //     this.verifySubmitDisabled();
      // }
  }

  changeStreetHandler=async event =>
  {

      await  this.setState({ street: event.target.value })
      if(sessionStorage.getItem("stg_loggedInHeader") || sessionStorage.getItem("stg_acq_exitingUser") === "ON" || sessionStorage.getItem("preAuth") ==="ON")
      {
          if(this.state.streetSubmit!=this.state.street)
          {
              this.setState({submitDisabled : false})
          }
          else if(this.state.streetSubmit===this.state.street)
          {
              this.setState({submitDisabled : true})
          }
      }
      else
      {
          this.verifySubmitDisabled();
      }

  }

  addPincodeHandler=async event =>
  {
      await this.setState({ addPinCodeNumber: event.target.value.replace(/\D/g, '') });
      // await this.setState({ phoneNumber: e.target.value.replace(/\D/g, '') });
      if(sessionStorage.getItem("stg_acq_freshUser") === "ON")
      {
          this.verifySubmitDisabled();
      }
  }

  addStateHandler=async event =>
  {
      await this.setState({ addStateName: event.target.value }); 
      if(sessionStorage.getItem("stg_acq_freshUser") === "ON")
      {
          this.verifySubmitDisabled();
      }
  }

  
  addLocalityHandler=async event =>
  {
      await this.setState({ addLocalityName: event.target.value });   
      if(sessionStorage.getItem("stg_acq_freshUser") === "ON")
      {
          this.verifySubmitDisabled();
      }
   }

  addSocityHandler=async event =>
  {
      await this.setState({ addSocityName: event.target.value }); 
      // if(sessionStorage.getItem("stg_acq_freshUser") === "ON")
      // {
      //     this.verifySubmitDisabled();
      // }
  }

  addCityHandler=async event =>
  {
      await this.setState({ addCityName: event.target.value }); 
      if(sessionStorage.getItem("stg_acq_freshUser") === "ON")
      {
          this.verifySubmitDisabled();
      }
  }

  verifySubmitDisabled()
  {

      if(this.state.flatNumber.length >0 && this.state.street.length>0 && this.state.addPinCodeNumber.length===6 && 
          this.state.addStateName.length>0 && this.state.addLocalityName.length>0 && this.state.addCityName.length>0)
           
          {
              this.setState({submitDisabled : false})
          }

      else
      {
          this.setState({submitDisabled : true})
      }
  }

  resetInput=async e =>
  {
      e.target.value = "";
  }

  fetchData = (inputValue, callback) => 
  {
      var locationcode = this.state.locationCode;
      const tempArray = [];
      setTimeout(() => {
          if(inputValue.length ===3)
          {
              UserService.getLocalities(locationcode,inputValue).then(res => {
              if (res.data.success) 
              {
                  this.setState({ localityList: res.data.response });
                  res.data.response.forEach((element) => 
                  {
                      tempArray.push({
                      label: `${element.name}`,
                      value: element.id,
                      });
                  });
              }
              else 
              {
                  this.msg(res.data.msg);
              }
                  this.setState({ localityNameList: tempArray });

              callback(this.state.localityNameList);
          }).catch(e => {
              if(inputValue.length ===3)
              {
                  this.msg("No matched found with given search term");
              }
          })
      }
      }, 100);
  };

  handleChangeLocality=async (event) =>
  {
      
      await this.setState({ localityId: event.value })
      if(this.state.localityList.length!==0)
      {
          var localityDetails = this.state.localityList.filter(obj => event.value === obj.id);
           if (localityDetails.length >= 0) {
              this.setState({ city: localityDetails[0].city || 'NA' });
              this.setState({ state: localityDetails[0].state || 'NA' });
              this.setState({ pincode: localityDetails[0].pincode || 'NA' })
              this.getAllSocieties(localityDetails[0].id);     
          }
      }

      if(this.state.localityIdSubmit!=this.state.localityId)
      {
          this.setState({submitDisabled : false})
      }
      else if(this.state.localityIdSubmit===this.state.localityId)
      {
          this.setState({submitDisabled : true})
      }
      // if(this.state.flatNumberSubmit===this.state.flatNumber && this.state.floorSubmit===this.state.floor && this.state.streetSubmit===this.state.street && this.state.societyIdSubmit===this.state.societyId )
      // {
      //     if(this.state.localityIdSubmit!=this.state.localityId)
      //     {
      //         this.setState({submitDisabled : false})
      //     }
      //     else if(this.state.localityIdSubmit===this.state.localityId)
      //     {
      //         this.setState({submitDisabled : true})
      //     }
      // }
  }

  handleChangeSociety=async event =>
  {

       await this.setState({ societyId: event.value })

       if(this.state.societyIdSubmit!=this.state.societyId)
       {
           this.setState({submitDisabled : false})
       }
       else if(this.state.societyIdSubmit===this.state.societyId)
       {
           this.setState({submitDisabled : true})
       }

  }

  getAllSocieties(id) 
  {

      this.setState({ societyList: "" })
      var localityId = id;
      if (localityId !== "") 
      {
          UserService.getSocieties(localityId).then(res => 
          {

              if (res.data.success) {
                  this.setState({ mandatoryIcon: true })
                  this.setState({ societyList: res.data.response })
                  this.setState({ isSocityNotFound: false })
              }
          }).catch(err => 
          {
              this.setState({ mandatoryIcon: false })
              this.setState({ isSocityNotFound: true })
              // isSocityNotFound
              // this.msg("No society found")
          })
      }
  }

  changePincodeHandler(event) {
      this.setState({ pincode: event.target.value })
  }

  handleSubmit(event) 
  {
      event.preventDefault();
      sessionStorage.setItem("stg_mo_redirectFrom","Click on Submit Button on Change Address Pop UP Model")
      const data = new FormData(event.target);

      var moe_updateAddress = data.get("flatNo")+","+data.get("floor")+","+data.get("street")+","+data.get("society")+","+data.get("locality")+","+data.get("pincode");
      sessionStorage.setItem("stg_new_address_DeliverPage_updated",moe_updateAddress);
      this.moengageAddressSubmit_Clicked();

      var addressDetails = {};
      addressDetails['flat_no'] = data.get("flatNo");
      addressDetails['floor'] = data.get("floor");
      addressDetails['block_or_street'] = data.get("street");
      addressDetails['suburb_non_master'] = "";
      addressDetails['society'] = data.get("society");
      addressDetails['society_non_master'] = "";
      addressDetails['locality'] = data.get("locality");
      addressDetails['city_non_master'] = data.get("locality");
      addressDetails['pin_code'] = data.get("pincode");

      if(sessionStorage.getItem("stg_acq_freshUser")==="ON") 
      {
          addressDetails['city'] = data.get("city");
          addressDetails['state'] = data.get("state");
          sessionStorage.setItem("stg_nda_adressDetails", JSON.stringify(addressDetails));
            
          this.setState({flatNumber: JSON.parse(sessionStorage.getItem('stg_nda_adressDetails')).flat_no || "NA" })
          this.setState({floor: JSON.parse(sessionStorage.getItem('stg_nda_adressDetails')).floor || "NA"})
          this.setState({street: JSON.parse(sessionStorage.getItem('stg_nda_adressDetails')).block_or_street || "NA" })
          this.setState({localityName: JSON.parse(sessionStorage.getItem('stg_nda_adressDetails')).locality})
          this.setState({pincode: JSON.parse(sessionStorage.getItem('stg_nda_adressDetails')).pin_code || "NA" })
          this.setState({state: JSON.parse(sessionStorage.getItem('stg_nda_adressDetails')).state })
          this.setState({city: JSON.parse(sessionStorage.getItem('stg_nda_adressDetails')).city || "NA" })

     }


      if(sessionStorage.getItem("stg_loggedInHeader") || sessionStorage.getItem("stg_acq_exitingUser") === "ON" || sessionStorage.getItem("preAuth") ==="ON")
      {
              var accountId = JSON.parse(sessionStorage.getItem('orderDetails')).id;
              var des = "Address Change";
              UserService.updateAddress(addressDetails, accountId, des).then(res => {
                  if (res.data.success) 
                  {
                    //   this.msg("Address changed Successfully");
                    this.props.popUpSuccess(true, "Your request has been submitted successfully. Our backend team will validate and update the same.");
                  } 
                  else
                  {
                      if(res.data.msg.includes("A Case already exists for this type"))
                      {
                        //   this.msg("Your last address change request is still pending for updation");
                          this.props.popUpSuccess(true, "Your last address change request is still pending for updation!");
                          this.setState({ submitDisabled: true})
                      }

                      else
                      {
                          this.msg(res.data.msg);
                          this.setState({ submitDisabled: true})
                      }
                  }
              }).catch(err => {
                  this.msg("Something went wrong. Please contact BPO team.")
                  this.setState({ submitDisabled: true})
              })
      }
      else
      {
          this.msg("Address added and ready for submisson!")
          this.props.addAddresCall();
      }
      this.props.closeaddressChange()
  }

  render() {
      var localityOptions = [];
      let localityNameFinal;

      if(this.state.localityList.length===0)
      {

          var data = {}
          data["label"] = this.state.localityName;
          data["value"] = this.state.localityId;
          localityOptions.push(data);

          localityNameFinal=localityOptions.filter(obj => obj.value === this.state.localityId)
      }
      else
      {
          localityNameFinal=this.state.localityNameList.filter(obj => obj.value === this.state.localityId)
      }
      
      

  
      let localityRender = 
      <AsyncSelect
          value={localityNameFinal}
          onChange={e=>this.handleChangeLocality(e)}
          loadOptions={this.fetchData}
          className="form-control custom-fctl"
          name="locality"
          // isClearable="true"
          // isMulti
      />

        let SocietyRender;

        if(this.state.isSocityNotFound)
        {
            SocietyRender = <label style={{color: "red"}}><sup>*</sup> <small> Socity Not Found for selected locality</small></label>
        }

        else if (this.state.societyList !== "") 
        {

          var societyOptions = [];
          this.state.societyList.sort((a, b) => (a.name > b.name) ? 1 : -1).forEach(function (entry) {
              var data = {}
              data["label"] = entry.name;
              data["value"] = entry.id;
              societyOptions.push(data);
          });

           SocietyRender = 
           <Select
              value={societyOptions.filter(obj => obj.value === this.state.societyId )}
              onChange={this.handleChangeSociety}
              options={societyOptions}
              className="form-control custom-fctl"
              name="society"
              placeholder="Select society"
          />
        }
        return (
            <section>
                <div className='popup'>
                    <div className="container pt-lg-5 pt-2">
                        <div className="bd-example-modal-lg address-change-modal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                            <div className="modal-dialog modal-dialog-centered addressPopStyle">
                                <div className="modal-content">
                                    <div className="modal-header">
                                    {sessionStorage.getItem("stg_loggedInHeader") || sessionStorage.getItem("stg_acq_exitingUser")==="ON" || sessionStorage.getItem("preAuth") ==="ON"?( <h4 className="blackClourV2">Change Address</h4>):(<h4 className="blackClourV2">Add Address</h4>)}
                                        <button type="button" className="close " onClick={this.props.closeaddressChange} data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="container">
                                            <form onSubmit={this.handleSubmit}>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label forhtml="" className="">Flat No/House No <sup>*</sup></label>
                                                            <input type="text" className="form-control custom-fctl" name="flatNo" maxLength="10" value={this.state.flatNumber} placeholder={this.state.flatNum} onChange={this.changeFlatHandler} id="exampleInputFlat" aria-describedby="emailHelp" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label forhtml="exampleInputFloor">Floor</label>
                                                            <input type="text" value={this.state.floor} name="floor" maxLength="5" onChange={this.changeFloorHandler} className="form-control custom-fctl" id="exampleInputFloor" aria-describedby="emailHelp" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label forhtml="exampleInputBlock">Block/Street/Plot No/Building No/Wing <sup>*</sup></label>
                                                            <input type="text" value={this.state.street} name="street" maxLength="150" onChange={this.changeStreetHandler} className="form-control custom-fctl" id="exampleInputBlock" aria-describedby="emailHelp" />
                                                        </div>
                                                    </div>
                                                    {sessionStorage.getItem("stg_loggedInHeader") || sessionStorage.getItem("stg_acq_exitingUser")==="ON" || sessionStorage.getItem("preAuth") ==="ON"?( 
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label forhtml="exampleInputLocality">Locality <sup>*</sup> <small> (enter only first 3 characters to search a new locality)</small></label>
                                                            {localityRender}
                                                    </div>
                                                    </div>):
                                                    (
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label forhtml="" className="">Locality <sup>*</sup></label>
                                                            <input type="text" className="form-control custom-fctl" name="locality" maxLength="15" value={this.state.addLocalityName} placeholder={this.state.addLocalityNum} onChange={this.addLocalityHandler} id="exampleInputLocality" aria-describedby="emailHelp" />
                                                        </div>
                                                        </div>
                                                    )}
                                                    
                                                    {sessionStorage.getItem("stg_loggedInHeader") || sessionStorage.getItem("stg_acq_exitingUser")==="ON" || sessionStorage.getItem("preAuth") ==="ON"?( 
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label forhtml="exampleInputSociety">Society</label>
                                                            {SocietyRender}
                                                        </div>
                                                    </div>):
                                                    (
                                                        <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label forhtml="" className="">Society</label>
                                                            <input type="text" className="form-control custom-fctl" name="society" maxLength="25" value={this.state.addSocityName} placeholder={this.state.addSocityNum} onChange={this.addSocityHandler} id="exampleInputSociety" aria-describedby="emailHelp" />
                                                        </div>
                                                        </div>
                                                    )}
                                                    {sessionStorage.getItem("stg_loggedInHeader") || sessionStorage.getItem("stg_acq_exitingUser")==="ON" || sessionStorage.getItem("preAuth") ==="ON"?( 
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label forhtml="exampleInputCity1">City <sup>*</sup></label>
                                                            <input value={this.state.city || 'NA'} type="text" name="city" onChange={this.changeHandler} className="form-control custom-fctl" placeholder="delhi" disabled />
                                                        </div>
                                                    </div>
                                                    ):
                                                    (
                                                        <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label forhtml="" className="">City <sup>*</sup> </label>

                                                            {/* {sessionStorage.getItem("preAuth")==="ON" ?
                                                                ( 
                                                                <input type="text" className="form-control custom-fctl" name="city" maxLength="15" value={this.state.addCityName} placeholder={this.state.addCityNum}  onChange={this.addCityHandler} id="exampleInputCity2" aria-describedby="emailHelp" />

                                                                ):
                                                                (
                                                                    <input type="text" className="form-control custom-fctl" name="city" maxLength="15" value={this.state.addCityName} placeholder={this.state.addCityNum} readOnly={true} id="exampleInputCity2" aria-describedby="emailHelp" />

                                                                )
                                                            } */}
                                                        <input type="text" className="form-control custom-fctl" name="city" maxLength="15" value={this.state.addCityName} placeholder={this.state.addCityNum} readOnly={true} id="exampleInputCity2" aria-describedby="emailHelp" />


                                                        </div>
                                                        </div>
                                                    )}
                                                    {sessionStorage.getItem("stg_loggedInHeader") || sessionStorage.getItem("stg_acq_exitingUser")==="ON" || sessionStorage.getItem("preAuth") ==="ON" ?( 

                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label forhtml="exampleInputState">State <sup>*</sup></label>
                                                            <input value={this.state.state || 'NA'} type="text" name="state" onChange={this.changeHandler} className="form-control custom-fctl" disabled />
                                                        </div>
                                                    </div>
                                                    ):
                                                    (
                                                        <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label forhtml="" className="">State <sup>*</sup></label>
                                                            {/* {sessionStorage.getItem("preAuth")==="ON" ?
                                                                ( 
                                                                    <input type="text" className="form-control custom-fctl" name="state" maxLength="15" value={this.state.addStateName} placeholder={this.state.addStateNum} onChange={this.addStateHandler} id="exampleInputState" aria-describedby="emailHelp" /> 
                                                                ):
                                                                (
                                                                    <input type="text" className="form-control custom-fctl" name="state" maxLength="15" value={this.state.addStateName} placeholder={this.state.addStateNum} readOnly={true} id="exampleInputState" aria-describedby="emailHelp" />

                                                                )
                                                                } */}
                                                        <input type="text" className="form-control custom-fctl" name="state" maxLength="15" value={this.state.addStateName} placeholder={this.state.addStateNum} readOnly={true} id="exampleInputState" aria-describedby="emailHelp" />

                                                        </div>
                                                        </div>
                                                    )}
                                                    {sessionStorage.getItem("stg_loggedInHeader") || sessionStorage.getItem("stg_acq_exitingUser")==="ON" || sessionStorage.getItem("preAuth") ==="ON"? ( 
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label forhtml="exampleInputPincode">Pincode </label>
                        
                                                            <input value={this.state.pincode} name="Pincode" type="text" onChange={this.changeHandler} className="form-control custom-fctl"  disabled />

                                                        </div>
                                                    </div>
                                                    ):
                                                    (
                                                        <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label forhtml="" className="">Pincode <sup>*</sup> <small> (Pincode must be 6 digits number)</small></label>
                                                            <input type="tel" className="form-control custom-fctl" name="pincode" maxLength="6" value={this.state.addPinCodeNumber} placeholder={this.state.addPincodeNum} onChange={this.addPincodeHandler} id="exampleInputPincode" aria-describedby="emailHelp" />
                                                        </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="pb-4 text-center">
                                                    <button type="submit" className="btn save-btn" disabled={this.state.submitDisabled}>Submit</button>
                                                </div>
                                            </form>
                                            <ToastContainer />

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        );
    }
}
