import React from 'react'
import '../css/StyleV2.css'
import PreAuthbackImage from "../images/preauthBackImage.png";

const PreAuthBackground =(props) =>
{
    
        return(
            <div>
                <img className="preauthbackimg" src={PreAuthbackImage} size ='50px'/>
                <br></br>
                <p className="preauthbacktxt blackClourV2">Please wait while we fetch your account details ...</p>
            </div>
        )
        
}

export default PreAuthBackground;