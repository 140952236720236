import React from "react";
import "../../css/Acquisition.css";
import 'font-awesome/css/font-awesome.min.css';
import '@fontsource/poppins';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import chandigharSvg from "../../images/Acquisition/chandigarh.svg";
import amritsarSvg from "../../images/Acquisition/amritsar.svg";
import delhiSvg from "../../images/Acquisition/delhi.svg";
import mumbaiSvg from "../../images/Acquisition/mumbai.svg";
import kanpurSvg from "../../images/Acquisition/kanpur.svg";
import bangaluruSvg from "../../images/Acquisition/bangaluru.svg";
import ahmedabadSvg from "../../images/Acquisition/ahmedabad.svg";
import chennaiSvg from "../../images/Acquisition/chennai.svg";
import hyderabadSvg from "../../images/Acquisition/hyderabad.svg";
import kolkataSvg from "../../images/Acquisition/kolkata.svg";
import cityDefaultSvg from "../../images/Acquisition/city_default_img.svg";
import hindustan_times from "../../images/Acquisition/hindustan_times.svg";
import livemint from "../../images/Acquisition/livemint.svg";
import UserService from "../../services/UserService";
import { getUtmSource,registrationGetCityName } from "../../common/commonFuntion";
import AuthService from "../../services/AuthService";
import ReactGA from 'react-ga';
import AllSchemes from "../../components/AllSchemes";
import Order from "../../components/Acquisition/Order";
import LoginSidebar from "../../subComponents/LoginSidebar";
import PageLoader from "../../subComponents/PageLoader";
import PopupContactus from "../../subComponents/PopupContactus";
import Footer from '../../components/Footer';

// import PageLoader from '../../subComponents/PageLoader';


// const AllSchemes = React.lazy(() => import('../../components/AllSchemes'))
// const AcqLogin = React.lazy(() => import('../../components/Acquisition/AcqLogin'))
// const AcqHeader = React.lazy(() => import('../../subComponents/AcqHeader'))
// const PageLoader = React.lazy(() => import('../../subComponents/PageLoader'))

const cityImagesArr = {
  delhi: delhiSvg,
  mumbai: mumbaiSvg,
  kanpur: kanpurSvg,
  amritsar: amritsarSvg,
  cpm: chandigharSvg,
  bangalore: bangaluruSvg,
  kolkata: kolkataSvg,
  chennai: chennaiSvg,
  hyderabad: hyderabadSvg,
  ahmedabad: ahmedabadSvg,
};
const publicationImg = { HT: hindustan_times, Mint: livemint, BP: livemint };

function sortCitybyOrder(a, b) {
  const cityNameOrder = [
    "delhi",
    "mumbai",
    "kanpur",
    "amritsar",
    "cpm",
    "bangalore",
    "kolkata",
    "chennai",
    "hyderabad",
    "ahmedabad",
  ];
  return (
    cityNameOrder.indexOf(a.name.toLowerCase()) -
    cityNameOrder.indexOf(b.name.toLowerCase())
  );
}

class AcqHomepage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saveValue: "",
      yearPlan: "",
      coverPrice: "",
      discountedPrice: "",
      perDayValue: "",
      pageLoader: true,
      defaultLowestScheme: [],
      cityList: [],
      selectedCityIndex: "",
      showPincodeBar: true,
      showProeedBtn: true,
      showAcquisitionModel: true,
      showLoginModel: false,
      showCheckoutModel: false,
      showDefaultSchmeLabel: true,
      isCheckedWhatsappBox: true,
      pincodeValue: "",
      showPincodeServiceAbleIssue: false,
      showAcquisitionHeader: true,
      pincodeValueErrorMsg: false,
      cityPublicationScheme: [],
      publicationCheckedStatus: "",
      seeLessCityView: true,
      seeMoreCityView: false,
      showPopupAlertState: false,
      orderData: {
        planId: "",
        planName: "",
        planPrice: "",
        coverPrice: "",
        planDuration: "",
        cityName: "",
        cityPincode: "",
      },
      height: window.innerHeight,
    };
    this.cityChange = this.cityChange.bind(this);
    this.publicationChange = this.publicationChange.bind(this);
    this.redirectToHome = this.redirectToHome.bind(this);
  }

  redirectToHome() {
    window.location.href = window.location.origin;
  }

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }
  
  handleWindowSizeChange = () => {
    this.setState({ height: window.innerHeight });
  };

  handleInputValue = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value == "" || re.test(e.target.value)) {
      this.setState({ pincodeValue: e.target.value });
    }
    //this.setState({ pincodeValue: e.target.value });
    if (this.state.pincodeValue > 5) {
      this.setState({ showPincodeServiceAbleIssue: false });
      this.setState({ pincodeValueErrorMsg: false });
    }
  };

  landToCheckout = () => {
    this.setState({ showLoginModel: false });
    this.setState({ showCheckoutModel: true });
    this.setState({ showAcquisitionHeader: false });
  };

  handleWhatsappChange = () => {
    this.setState({ isCheckedWhatsappBox: !this.state.isCheckedWhatsappBox });
    sessionStorage.setItem(
      "stg_isWhatsappChecked",
      !this.state.isCheckedWhatsappBox
    );
  };

  /* comment by mohit -2-sep-22 this function is not in used
        getSubscriptionValue = (data, type) => 
        {  
            if(type==="default")
            {
                this.setState({ cityList: data.cities });
                this.setState({ discountedPrice: data.lowest_priced_scheme.price });
                this.setState({ coverPrice: data.lowest_priced_scheme.cover_price });
                let saveValueData= ((data.lowest_priced_scheme.price/data.lowest_priced_scheme.cover_price)*100)
                this.setState({ saveValue:saveValueData.toFixed(0) });
                let yearPlanData = 0;
                let perDayValueData=0;
                if(data.lowest_priced_scheme.price===12)
                {

                    yearPlanData= 1
                    perDayValueData=data.lowest_priced_scheme.price/365
                }
                else if(data.lowest_priced_scheme.price===24)
                {
                    yearPlanData= 2
                    perDayValueData=data.lowest_priced_scheme.price/(365+365)

                }
                else
                {
                    yearPlanData= 1
                    perDayValueData=data.lowest_priced_scheme.price/365

                }
                this.setState({ yearPlan: yearPlanData +" Year" });
                this.setState({ perDayValue: perDayValueData.toFixed(2) });
            }
            else
            {
                this.setState({ discountedPrice: data.scheme.price });
                this.setState({ coverPrice: data.scheme.cover_price });
                let saveValueData= ((data.scheme.price/data.scheme.cover_price)*100)
                this.setState({ saveValue:saveValueData.toFixed(0) });

                let yearPlanData = 0;
                let perDayValueData=0;
                if(data.scheme.price===12)
                {

                    yearPlanData= 1
                    perDayValueData=data.scheme.price/365
                }
                else if(data.scheme.price===24)
                {
                    yearPlanData= 2
                    perDayValueData=data.scheme.price/(365+365)

                }
                else
                {
                    yearPlanData= 1
                    perDayValueData=data.scheme.price/365

                }
                sessionStorage.setItem("moe_acq_selected_yearPlan",yearPlanData)
                sessionStorage.setItem("moe_acq_selected_planPrice",data.scheme.price)
                this.setState({ yearPlan: yearPlanData +" Year" });
                this.setState({ perDayValue: perDayValueData.toFixed(2)});
            }
        }; 
    */
  cityChange(e) {
    let index = e.target.value;
    if (index === "-1") {
      //this.getSubscriptionValue(this.state.defaultLowestScheme, "default");
      this.setState({ showPincodeBar: false });
      this.setState({ showProeedBtn: false });
      this.setState({ showPincodeServiceAbleIssue: false });
      this.setState({ showDefaultSchmeLabel: true });
    } else {
      let cityPublicationObj = Object.values(
        this.state.cityList[index].publication_wise_schemes
      ).reverse();
      let selectedPublicationVal = cityPublicationObj.find(
        (item) => item.publications == this.state.publicationCheckedStatus
      );
      if (selectedPublicationVal == undefined) {
        selectedPublicationVal = cityPublicationObj[0];
      }
      let yearPlanData = selectedPublicationVal.duration
        ? Math.floor(selectedPublicationVal.duration / 12)
        : "";      
      this.setState({
        publicationCheckedStatus: selectedPublicationVal.publications,
      });
      this.setState({ selectedCityIndex: index });
      this.setState({ showPincodeServiceAbleIssue: false });
      this.setState({ pincodeValueErrorMsg: false });
      this.setState({ cityPublicationScheme: cityPublicationObj });
      //this.getSubscriptionValue(this.state.cityList[index], e.target.value);
      if (this.state.cityList[index].pincode_required === true) {
        this.setState({ showPincodeBar: true });
        this.setState({ showProeedBtn: true });
        this.setState({ pincodeValue: "" });
        this.setState({ showDefaultSchmeLabel: false });
      } else {
        this.setState({ showProeedBtn: true });
        this.setState({ showPincodeBar: false });
        this.setState({ showDefaultSchmeLabel: false });
      }

      sessionStorage.setItem("stg_scheme_id", selectedPublicationVal.id);
      sessionStorage.setItem(
        "stg_location_code",
        this.state.cityList[index].id
      );
      sessionStorage.setItem("stg_city", this.state.cityList[index].name);
      sessionStorage.setItem("stg_salesOrg", selectedPublicationVal.code);
      sessionStorage.setItem("stg_selectedPlan_Name", selectedPublicationVal.publications);

      sessionStorage.setItem("moe_acq_selected_yearPlan", yearPlanData);
      sessionStorage.setItem(
        "moe_acq_selected_planPrice",
        selectedPublicationVal.price
      );
      sessionStorage.setItem(
        "moe_acq_selected_coverPrice",
        selectedPublicationVal.cover_price
      );
    }

    this.moengageAqu_CityDropDown_Selected();
  }

  publicationChange(e) {
    let selectscheme = e.target.value;
    let cityindex = this.state.selectedCityIndex;

    let cityPublicationObj = Object.values(
      this.state.cityList[cityindex].publication_wise_schemes
    ).reverse();
    let selectedPublicationVal = cityPublicationObj.find(
      (item) => item.publications == selectscheme
    );

    if (selectedPublicationVal == undefined) {
      selectedPublicationVal = cityPublicationObj[0];
    }

    let yearPlanData = selectedPublicationVal.duration
      ? Math.floor(selectedPublicationVal.duration / 12)
      : "";

    sessionStorage.setItem("stg_scheme_id", selectedPublicationVal.id);
    sessionStorage.setItem("stg_salesOrg", selectedPublicationVal.code);
    sessionStorage.setItem(
      "stg_selectedPlan_Name",
      selectedPublicationVal.publications
    );
    sessionStorage.setItem("moe_acq_selected_yearPlan", yearPlanData);
    sessionStorage.setItem(
      "moe_acq_selected_planPrice",
      selectedPublicationVal.price
    );
    sessionStorage.setItem(
      "moe_acq_selected_coverPrice",
      selectedPublicationVal.cover_price
    );
    this.setState({ publicationCheckedStatus: selectscheme });

    this.moengageAqu_publication_Selected();
  }

  moengageAqu_NewCustomer_Viewed() {
    if (process.env.REACT_APP_ENABLE_MOENGAGE === "true") {
      if (sessionStorage.getItem("stg_mo_redirectFrom") === null) {
        sessionStorage.setItem(
          "stg_mo_redirectFrom",
          "Acquisition HT Reader.com"
        );
      }
      getUtmSource();
      window.Moengage.track_event("Acquisition HTreaders New Customer Viewed", {
        Campaign_Source: sessionStorage.getItem("stg_utm_source"),
        Campaign_Name: sessionStorage.getItem("stg_utm_name"),
        Campaign_Medium: sessionStorage.getItem("stg_utm_medium"),
        Redirect_From: sessionStorage.getItem("stg_mo_redirectFrom"),
        Mobile_Number: sessionStorage.getItem("acqPhoneNumber"),
      });
    }
  }

  moengageAqu_CityDropDown_Selected() {
    if (process.env.REACT_APP_ENABLE_MOENGAGE === "true") {
      window.Moengage.track_event("Acquisition Selected City", {
        Campaign_Source: sessionStorage.getItem("stg_utm_source"),
        Campaign_Name: sessionStorage.getItem("stg_utm_name"),
        Campaign_Medium: sessionStorage.getItem("stg_utm_medium"),
        Redirect_From: sessionStorage.getItem("stg_mo_redirectFrom"),
        Plan_Name: sessionStorage.getItem("stg_selectedPlan_Name"),
        Plan_Duration: sessionStorage.getItem("moe_acq_selected_yearPlan"),
        Plan_Price: sessionStorage.getItem("moe_acq_selected_planPrice"),
        City: sessionStorage.getItem("stg_city"),
        Mobile_Number: sessionStorage.getItem("acqPhoneNumber"),
      });
    }
  }

  moengageAqu_publication_Selected() {
    if (process.env.REACT_APP_ENABLE_MOENGAGE === "true") {
      window.Moengage.track_event("Acquisition Selected Publication", {
        Campaign_Source: sessionStorage.getItem("stg_utm_source"),
        Campaign_Name: sessionStorage.getItem("stg_utm_name"),
        Campaign_Medium: sessionStorage.getItem("stg_utm_medium"),
        Redirect_From: sessionStorage.getItem("stg_mo_redirectFrom"),
        Plan_Name: sessionStorage.getItem("stg_selectedPlan_Name"),
        Plan_Duration: sessionStorage.getItem("moe_acq_selected_yearPlan"),
        Plan_Price: sessionStorage.getItem("moe_acq_selected_planPrice"),
        City: sessionStorage.getItem("stg_city"),
        Mobile_Number: sessionStorage.getItem("acqPhoneNumber"),
      });
    }
  }

  moengageAqu_ProceedClick() {
    if (process.env.REACT_APP_ENABLE_MOENGAGE === "true") {
      sessionStorage.setItem(
        "stg_mo_redirectFrom",
        "Clicked on Proceed button and redirection from Acquisition landing page"
      );
      window.Moengage.track_event("Acquisition Proceed Click", {
        Campaign_Source: sessionStorage.getItem("stg_utm_source"),
        Campaign_Name: sessionStorage.getItem("stg_utm_name"),
        Campaign_Medium: sessionStorage.getItem("stg_utm_medium"),
        Redirect_From: sessionStorage.getItem("stg_mo_redirectFrom"),
        Plan_Name: sessionStorage.getItem("stg_selectedPlan_Name"),
        Plan_Duration: sessionStorage.getItem("moe_acq_selected_yearPlan"),
        Plan_Price: sessionStorage.getItem("moe_acq_selected_planPrice"),
        City: sessionStorage.getItem("stg_city"),
        Pincode: sessionStorage.getItem("moe_acq_Pincode"),
        Mobile_Number: sessionStorage.getItem("acqPhoneNumber"),
      });
    }
  }

  componentDidMount() {
    ReactGA.ga('send','pageview','/newCustomer');
    AuthService.getAuth("htprint", "htprint");
    if (sessionStorage.getItem("redirect_loginPage") !== "true") {
      window.Moengage.destroy_session();
    }
    this.moengageAqu_NewCustomer_Viewed();
    sessionStorage.removeItem("stg_scheme_id");
    sessionStorage.removeItem("selectedSchemes");
    sessionStorage.removeItem("stg_acq_exitingUser");
    sessionStorage.removeItem("stg_acq_freshUser");
    sessionStorage.removeItem("orderDetails");
    sessionStorage.removeItem("stg_isWhatsappChecked");
    sessionStorage.removeItem("stg_location_code");
    sessionStorage.removeItem("lead_id");
    sessionStorage.setItem("createNewAccountActive", "ON");
    sessionStorage.setItem("stg_acq_userType","New User")
    sessionStorage.setItem("stg_userJourneyType", "Acquisition");

    this.setState({ ordersLoading: true });
    UserService.acqSubscriptionDetails()
      .then((response) => {
        if (response.data !== "") {
          response.data.cities.sort(sortCitybyOrder);
          this.setState({ orders: response.data });
          this.setState({ pageLoader: false });
          this.setState({ defaultLowestScheme: response.data });
          {
            /* 02-sep this.getSubscriptionValue(response.data, "default"); */
          }
          this.setState({ cityList: response.data.cities });

          // Set  Default Delhi city value //
          let getdelhicitydata = this.state.cityList.find(
            (item) => item.name.toLowerCase() == "delhi"
          );
          let cityPublicationObj = Object.values(
            getdelhicitydata.publication_wise_schemes
          ).reverse();
          let selectedPublicationVal = cityPublicationObj[0];
          let yearPlanData = selectedPublicationVal.duration
            ? Math.floor(selectedPublicationVal.duration / 12)
            : "";
          this.setState({ selectedCityIndex: 0 });
          this.setState({ cityPublicationScheme: cityPublicationObj });
          this.setState({
            publicationCheckedStatus: cityPublicationObj[0].publications,
          });

          sessionStorage.setItem("stg_scheme_id", selectedPublicationVal.id);
          sessionStorage.setItem("stg_location_code", getdelhicitydata.id);
          sessionStorage.setItem("stg_city", getdelhicitydata.name);
          sessionStorage.setItem("stg_salesOrg", selectedPublicationVal.code);
          sessionStorage.setItem(
            "stg_selectedPlan_Name",
            selectedPublicationVal.publications
          );
          sessionStorage.setItem("moe_acq_selected_yearPlan", yearPlanData);
          sessionStorage.setItem(
            "moe_acq_selected_planPrice",
            selectedPublicationVal.price
          );
          sessionStorage.setItem(
            "moe_acq_selected_coverPrice",
            selectedPublicationVal.cover_price
          );
          // end //
        }
      })
      .catch((err) => {});
    setTimeout(() => {
      this.setState({ isTimeOut: true });
    }, 60000 * 60);
  }
  showLogin = () => {
    this.setState((prevState) => ({
      orderData: {
        ...prevState.orderData,
        planId: sessionStorage.getItem("stg_scheme_id"),
        planName: sessionStorage.getItem("stg_selectedPlan_Name"),
        planPrice: sessionStorage.getItem("moe_acq_selected_planPrice"),
        coverPrice: sessionStorage.getItem("moe_acq_selected_coverPrice"),
        planDuration: sessionStorage.getItem("moe_acq_selected_yearPlan"),
        cityName: sessionStorage.getItem("stg_city"),
        cityPincode:
          typeof parseInt(this.state.pincodeValue) == "number"
            ? this.state.pincodeValue
            : "",
      },
    }));
    sessionStorage.removeItem("moe_acq_Pincode");
    if (!this.state.pincodeValue) {
      this.setState({ pincodeValueErrorMsg: true });
    }

    if (this.state.showPincodeBar) 
    {
      
      if(this.state.pincodeValue.length>0)
      {
        var source = "acquisition_journey";
        UserService.verifyAcqFreshPinCodeEligibility(
          this.state.pincodeValue,
          sessionStorage.getItem("stg_scheme_id"),
          source
          ) 
          .then((response) => {
            if (response.data.body.isEligible) 
            {
              if(sessionStorage.getItem("stg_city")==="Mumbai")
              {
                sessionStorage.setItem("moe_acq_Pincode", this.state.pincodeValue);
                window.location = 'https://accounts.hindustantimes.com?ref=htr';
                this.moengageAqu_ProceedClick();
              }
              else
              {
                this.setState({ showLoader: true });
                this.setState({ showPincodeServiceAbleIssue: false });
                this.setState({ showAcquisitionModel: false });
                this.setState({ showLoginModel: true });
                sessionStorage.setItem("moe_acq_Pincode", this.state.pincodeValue);
                sessionStorage.setItem("stg_city", response.data.body.city);
                setTimeout(() => {
                  this.setState({ showLoader: false });
                }, 700);
                this.setState((prevState) => ({
                  orderData: {
                    ...prevState.orderData,
                    planId: sessionStorage.getItem("stg_scheme_id"),
                    planName: sessionStorage.getItem("stg_selectedPlan_Name"),
                    planPrice: sessionStorage.getItem("moe_acq_selected_planPrice"),
                    coverPrice: sessionStorage.getItem("moe_acq_selected_coverPrice"),
                    planDuration: sessionStorage.getItem("moe_acq_selected_yearPlan"),
                    cityName: response.data.body.city,
                    cityPincode:
                      typeof parseInt(this.state.pincodeValue) == "number"
                        ? this.state.pincodeValue
                        : "",
                  },
                }));
            

                this.moengageAqu_ProceedClick();
                //snowplow_aqu_proceedClick();

                this.setState({ showAcquisitionModel: false });
                this.setState({ showPincodeServiceAbleIssue: false });
              }
    
            } 
            else
            {
              this.setState({ showPincodeServiceAbleIssue: true });
              this.setState({ pincodeValueErrorMsg: false });
              sessionStorage.setItem("moe_acq_Pincode", "Invaild Pincode");
              this.moengageAqu_ProceedClick();
              //snowplow_aqu_proceedClick();
            }
          })
          .catch((err) => {
            sessionStorage.setItem("moe_acq_Pincode", "Invaild Pincode");
            this.moengageAqu_ProceedClick();
            //snowplow_aqu_proceedClick();
          });
        }
        else
        {
          this.setState({ pincodeValueErrorMsg: true });
          this.setState({ showPincodeServiceAbleIssue: false });
          this.moengageAqu_ProceedClick();
        }
    } 
    
    else {
      this.setState({ showAcquisitionModel: false });
      this.setState({ showLoginModel: true });
      this.setState({ showPincodeServiceAbleIssue: false });
      sessionStorage.setItem("moe_acq_Pincode", "Not Required");
      this.moengageAqu_ProceedClick();
      //snowplow_aqu_proceedClick();
    }
    this.setState({ showLoader: false });

  };

 

  showMoreCities = () => {
    this.setState({ seeMoreCityView: true });
    this.setState({ seeLessCityView: false });

    this.setState({ showPincodeBar: false });
    this.setState({ pincodeValueErrorMsg: false });
    this.setState({ showPincodeServiceAbleIssue: false });

    var getMoreCityFirstCityVal =
      this.state.cityList.length > 8 ? this.state.cityList[8] : null;
    if (
      getMoreCityFirstCityVal &&
      typeof getMoreCityFirstCityVal != "undefined"
    ) {
      let cityPublicationObj = Object.values(
        getMoreCityFirstCityVal.publication_wise_schemes
      ).reverse();
      let selectedPublicationVal = cityPublicationObj[0];
      let yearPlanData = selectedPublicationVal.duration
        ? Math.floor(selectedPublicationVal.duration / 12)
        : "";

      this.setState({ cityPublicationScheme: cityPublicationObj });
      this.setState({
        publicationCheckedStatus: cityPublicationObj[0].publications,
      });

      sessionStorage.setItem("stg_scheme_id", selectedPublicationVal.id);
      sessionStorage.setItem("stg_location_code", getMoreCityFirstCityVal.id);
      sessionStorage.setItem("stg_city", getMoreCityFirstCityVal.name);
      sessionStorage.setItem("stg_salesOrg", selectedPublicationVal.code);
      sessionStorage.setItem(
        "stg_selectedPlan_Name",
        selectedPublicationVal.publications
      );
      sessionStorage.setItem("moe_acq_selected_yearPlan", yearPlanData);
      sessionStorage.setItem(
        "moe_acq_selected_planPrice",
        selectedPublicationVal.price
      );
    }
  };

  showLessCities = () => {
    this.setState({ seeMoreCityView: false });
    this.setState({ seeLessCityView: true });

    var getMoreCityFirstCityVal =
      this.state.cityList.length > 0 ? this.state.cityList[0] : null;
    if (
      getMoreCityFirstCityVal &&
      typeof getMoreCityFirstCityVal != "undefined"
    ) {
      let cityPublicationObj = Object.values(
        getMoreCityFirstCityVal.publication_wise_schemes
      ).reverse();
      let selectedPublicationVal = cityPublicationObj[0];
      let yearPlanData = selectedPublicationVal.duration
        ? Math.floor(selectedPublicationVal.duration / 12)
        : "";

      this.setState({ cityPublicationScheme: cityPublicationObj });
      this.setState({
        publicationCheckedStatus: cityPublicationObj[0].publications,
      });

      sessionStorage.setItem("stg_scheme_id", selectedPublicationVal.id);
      sessionStorage.setItem("stg_location_code", getMoreCityFirstCityVal.id);
      sessionStorage.setItem("stg_city", getMoreCityFirstCityVal.name);
      sessionStorage.setItem("stg_salesOrg", selectedPublicationVal.code);
      sessionStorage.setItem(
        "stg_selectedPlan_Name",
        selectedPublicationVal.publications
      );
      sessionStorage.setItem("moe_acq_selected_yearPlan", yearPlanData);
      sessionStorage.setItem(
        "moe_acq_selected_planPrice",
        selectedPublicationVal.price
      );
    }
  };

  showcontactUsPopUp = () => {
    this.setState({ showPopupAlertState: true });
  };

  closecontactUsPopUp = () => {
    this.setState({ showPopupAlertState: false });
  };

  render() {
    const { height } = this.state;
    const isMobile = height <= 500;
    var cityPublicationBlocks = Object.keys(
      this.state.cityPublicationScheme
    ).map((key) => {
        let durationInMonths = this.state.cityPublicationScheme[key].duration;
        let durationInYears = Math.floor(durationInMonths / 12);
        
        if (durationInYears < 2) {
            durationInYears = '';
        }
        
        let durationyear = "/ " + durationInYears + " Year";

      let saveAmtPer = Math.floor(
        100 -
          (this.state.cityPublicationScheme[key].price /
            this.state.cityPublicationScheme[key].cover_price) *
            100
      );
      return (
        <div className="ht_publication_tab" key={key}>
          <input
            type="radio"
            name="tabs"
            value={this.state.cityPublicationScheme[key].publications}
            id={key}
            onClick={this.publicationChange}
            checked={
              this.state.cityPublicationScheme[key].publications ==
              this.state.publicationCheckedStatus
                ? true
                : false
            }
          />
          <label htmlFor={key}>
            <div className="pricing-label_img">
                <img
                  src={
                    publicationImg[
                      this.state.cityPublicationScheme[key].publications
                    ]
                  }
                />
            </div>
            <div className="pricing-label-details">
              {this.state.cityPublicationScheme[key].cover_price > 0 && (
                <span className="saveLabel_discount" id="discPrice">
                  <span>Save</span>
                  <span className="percent">{saveAmtPer} % </span>
                  <span>Off</span>
                </span>
              )}

              <div className={`pricing-table purple ${this.state.cityPublicationScheme[key].cover_price > 0 ? 'withDiscount' : ''}`}>
                <div className="pricing-features">
                  <div className="feature">
                    ₹{this.state.cityPublicationScheme[key].price}
                  </div>
                </div>

                <div className="price-tag">
                    {this.state.cityPublicationScheme[key].cover_price > 0 ? (
                      <div className="stike">
                        <span className="font-weight-bold">₹{this.state.cityPublicationScheme[key].cover_price}</span>
                        <span className="">{durationyear}</span>
                      </div>
                    ) : (
                      <span className="">{durationyear}</span>
                    )}
                </div>
              </div>
            </div>
          </label>
        </div>
      );
    });

    var selectCityBlocks = this.state.cityList.map((option, index) => {
      if (index < 8)
        return (
          <div className="ht_tab" key={index}>
            <input
              type="radio"
              name="cities"
              value={index}
              id={option.name}
              onClick={this.cityChange}
              defaultChecked={
                option.name.toLowerCase() == "delhi" ? true : false
              }
            />
            <label className="circle_style mt-3" htmlFor={option.name}>
              
                <div className="city-img">
                  <img
                    className="img-fluid"
                    src={
                      cityImagesArr[option.name.toLowerCase()]
                        ? cityImagesArr[option.name.toLowerCase()]
                        : cityDefaultSvg
                    }
                    alt={option.name}
                  />
                </div>
                <div className="fnt-style">
                  <strong>
                    {registrationGetCityName(option.name)}
                  </strong>
                </div>
              
            </label>
            </div>
          
        );
    });

    var moreCityList = this.state.cityList.map((option, index1) => {
      if (index1 >= 8)
        return (
          <div className="ht_tab" key={index1}>
            <input
              type="radio"
              name="cities"
              value={index1}
              id={option.name}
              onClick={this.cityChange}
              defaultChecked={index1 == 8 ? true : false}
            />
            <label className="circle_style mt-3" htmlFor={option.name}>
              <div className="city-img">
                <img className="img-fluid"
                  src={
                    cityImagesArr[option.name.toLowerCase()]
                      ? cityImagesArr[option.name.toLowerCase()]
                      : cityDefaultSvg
                  }
                  width={55}
                  height={53}
                />
              </div>
              <div className="fnt-style">
              <strong>
                  {registrationGetCityName(option.name)}
                </strong>
              </div>
            </label>
          </div>
        );
    });

    return (
        <div>
          <div className="main-first">
            <div className="side-img">
              {this.state.showAcquisitionHeader && (
                <div className="col-md-4 div-left div-left-side-img">
                  <LoginSidebar />

                  {this.state.pageLoader && <PageLoader />}
                </div>
              )} 
              {this.state.showAcquisitionModel && (
                <div className="col-md-8 div-right">
                  <div className="ht_page">
                    {this.state.seeLessCityView == true && (
                      <div className="col-md-12 p-0">
                        <div className="back-btn-pincode">
                          <FontAwesomeIcon icon={faArrowLeft} size="2x" className="faArrowLeft" onClick={this.redirectToHome} />
                          <h6 className="mb-2 fnt-style-city1" >Select Your City</h6>
                        </div>
                        <div className="ht_city_selct allTabs">
                          {selectCityBlocks}
                        </div>
                      </div>
                    )}

                    {this.state.seeMoreCityView == true && (
                      <div className="col-md-12 pd_x_y">
                        <h6 className="mb-2 fnt-style-city1">Select Your City</h6>
                        <div className="ht_city_selct allTabs">{moreCityList}</div>
                      </div>
                    )}
                    {this.state.showPincodeBar && (
                      <div className="form-group blackClourV2 mt-3" id="pincodBox">
                        <input
                          className="form-control cus_sel_dropBox"
                          placeholder={"Please Enter The Pincode"}
                          value={this.state.pincodeValue}
                          type="text"
                          maxLength="6"
                          id="pincodBoxInput"
                          onChange={this.handleInputValue}
                        />
                      </div>
                    )}                

                    {this.state.showPincodeServiceAbleIssue &&
                    (
                        <div className="pincodeErrorMsg px-4" id="pincodeErr">
                          <span className="pincodeError">
                            * Sorry This Pincode Is Not Serviceable
                          </span>
                        </div>
                      )}

                    {this.state.pincodeValueErrorMsg && (
                      <div className="pincodeErrorMsg px-4" id="pincodeErr">
                        <span className="pincodeError">
                          * Please Enter The Pincode
                        </span>
                      </div>
                    )}

                    {this.state.cityList.length > 8 &&
                      this.state.seeLessCityView == true && (
                        <div className="title_more mt-3">
                          <i className="fa fa-angle-down"></i>
                          <span onClick={this.showMoreCities}>See More Cities</span>
                        </div>
                      )}
                    {this.state.cityList.length > 8 &&
                      this.state.seeMoreCityView == true && (
                        <div className="title_more mt-3">
                          <i className="fa fa-angle-up"></i>
                          <span onClick={this.showLessCities}>See Less Cities</span>
                        </div>
                      )}

                    <PopupContactus
                      showModalPopup={this.state.showPopupAlertState}
                      onPopupClose={this.closecontactUsPopUp}
                    ></PopupContactus>

                  </div>
                  <div className="ht_publication_selct">
                    {cityPublicationBlocks}
                  </div>
                  {this.state.showProeedBtn ||
                  this.state.pincodeValue.length > 5 ? (
                    <div className="whatsapp-main">
                      <input
                        type="checkbox"
                        id="Whatsapp"
                        name="WhatsappSelection"
                        value="Whatsapp"
                        checked={this.state.isCheckedWhatsappBox}
                        onChange={this.handleWhatsappChange}
                      />
                      <label className="ml-2 whtsapp_style" for="Whatsapp">
                        I accept to get the notifications through WhatsApp
                      </label>
                    </div>
                  ) : null}
                  <div className="cityFl_button mx-auto mt-1 mb-2">
                    {this.state.showProeedBtn ||
                    this.state.pincodeValue.length > 5 ? (
                      <button
                        className="btn proceedBtn"
                        id=""
                        onClick={() => this.showLogin()}
                        disabled={false}
                      >
                        Proceed
                      </button>
                    ) : (
                      <button className="btn proceedBtn" id="" disabled={true}>
                        Proceed
                      </button>
                    )}
                  </div>

                </div>  
              )}
              {this.state.showLoginModel && (
                <div className="col-md-8 div-right">
                    {this.state.showLoader ===true ? ( <PageLoader/> ) : ( 
                      <Order orderData={this.state.orderData} />
                    )}
                  {/* <Order orderData={this.state.orderData} /> */}
                </div>
              )}
              {this.state.showCheckoutModel && (
                <div>
                  <AllSchemes />
                </div>
              )}
            </div>
            <Footer />
          </div>
        </div>
    );
  }
}

export default AcqHomepage;