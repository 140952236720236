import React, { Component, Fragment } from 'react';  
import { Modal } from 'react-bootstrap';
import alertIcon from "../images/checkout/alert-icon.svg";
import './../css/Checkout.css';

class PopupAlert extends Component {  
    constructor(props) {  
        super(props);  
        this.state = {  
            showModal: false,
        };  
    }  
    
   componentDidMount() 
   {
   }
  
    isShowModal = (status) => {  
        this.handleClose();  
        this.setState({ showModal: status });  
    }  
  
    handleClose = () => { 
        sessionStorage.setItem("stg_mo_redirectFrom","Close the Alert on T&C")
        this.props.onPopupClose(false);  
    }  

  
    render() 
    {  
        return (  
            <Fragment>  
                <Modal show={this.props.showModalPopup} onHide={this.handleClose}  
                    size="lg"  
                    aria-labelledby="contained-modal-title-vcenter"  
                    centered  
                >  
                    <Modal.Header closeButton>  
                        <Modal.Title id="sign-in-title">  
                            <h4 className="text-center">{"Alert!"}</h4>
                         </Modal.Title>  
                    </Modal.Header>  
					<Modal.Body>  
                        <div>
                            <div className="container t-and-c">
                                <div className="row">
                                    <div className="col-md-12 t-and-c-div">
                                        <img src={alertIcon} />
                                        <h4 className="text-center">{"Please read the T&C and select the T&C checkbox"}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>  
                </Modal >  
            </Fragment > 
        );  
    }  
}  
  
export default (PopupAlert); 