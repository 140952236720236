import React, { Component, Fragment } from 'react';  
import './css/sso.css';
import Order from '../components/Acquisition/Order';
import UserService from '../services/UserService';
import PageLoader from '../subComponents/PageLoader';
import Home from '../components/HomeNJ';
// import AcquisitionLandingPage from './pages/Acquisition/AcqHomepage';
import AcquisitionLandingPage from '../pages/Acquisition/AcqHomepage';
import MobileLoginNJ from '../components/MobileLoginNJ';
import AuthService from '../services/AuthService';




// const AcquisitionLandingPage = React.lazy(() => import('./pages/Acquisition/AcqHomepage'))

class TrueCaller extends Component {  
    constructor(props) {  
        super(props);  
        this.state = {  
            mobileNumber:'',
            showLoader:true,
            showHomePage:false,
            showNewCustomerPage:false,
            showOrderPage:false,
            showLoginPage:false,
            notShowLoginPage:false,

            orderData: {
                planId: "",
                planName: "",
                planPrice: "",
                coverPrice: "",
                planDuration: "",
                cityName: "",
                cityPincode: "",
              },
        };  
    }  

    
   componentDidMount() 
   {
    AuthService.getAuth("htprint", "htprint");
    setTimeout(async ()=>
    {
        var myCookies = this.getCookies();
        if(
            window.location.href.includes("successPage") ||
            window.location.href.includes("TXN_SUCCESS") || 
            window.location.href.includes("errorPage")
        )
        {
            this.setState({ notShowLoginPage: true });
        }

        if(myCookies.token===undefined || myCookies.token===null || myCookies.token ==="")
        {

            this.setState({ showNewCustomerPage: false })
            this.setState({ showOrderPage: false })
            this.setState({ showLoader: false })
            this.setState({ showHomePage: false })
            this.setState({ showLoginPage: true })

        }
        else
        {
            UserService.getSSOTrueCaller(myCookies.token).then(response => 
            {
                if (response.data !== "") 
                {
                    if(response.data.id !==null)
                    {

                        sessionStorage.setItem("stg_mobile_number",response.data.mobile)
                        sessionStorage.setItem("orderDetails", JSON.stringify(response.data));
                        sessionStorage.setItem("default_gateway", response.data.defaultGateway)
                        sessionStorage.setItem("stg_vendor_name",response.data.vendorName)
                        sessionStorage.setItem("stg_vendor_mobile",response.data.vendorMobile);
                        sessionStorage.setItem("stg_vendor_code",response.data.vendorCode);
                        this.setState({ showNewCustomerPage: false })
                        this.setState({ showOrderPage: false })
                        this.setState({ showLoader: false })
                        this.setState({ showLoginPage: false })
                        this.setState({ showHomePage: true })

                    }

                    else if(window.location.href.includes("sso_source"))
                    {

                        if(response.data.mobile!==null)
                        {
                            sessionStorage.setItem("stg_mobile_number",response.data.mobile)
                        }
                        else
                        {
                            sessionStorage.setItem("stg_mobile_number","")

                        }
                        this.setState((prevState) => ({
                            orderData: {
                            ...prevState.orderData,
                            planId: sessionStorage.getItem("stg_scheme_id"),
                            planName: sessionStorage.getItem("stg_selectedPlan_Name"),
                            planPrice: sessionStorage.getItem("moe_acq_selected_planPrice"),
                            coverPrice: sessionStorage.getItem("moe_acq_selected_coverPrice"),
                            planDuration: sessionStorage.getItem("moe_acq_selected_yearPlan"),
                            cityName: sessionStorage.getItem("stg_city"),
                            cityPincode: sessionStorage.getItem("moe_acq_Pincode"),
                            
                            },
                        }));
                        this.setState({ showHomePage: false })
                        this.setState({ showNewCustomerPage: false })
                        this.setState({ showLoader: false })
                        this.setState({ showLoginPage: false })
                        this.setState({ showOrderPage: true })

                    }
                    else
                    {
                        this.setState({ showHomePage: false })
                        this.setState({ showOrderPage: false })
                        this.setState({ showLoader: false }) 
                        this.setState({ showLoginPage: false })
                        this.setState({ showNewCustomerPage: true })
                    }
                }
                }).catch(err => 
                    {
                        console.log("sso err",err)
                    })
            }
        }, 1000)
    }

   getCookies=()=>{
    var pairs = document.cookie.split(";");
    var cookies = {};
    for (var i=0; i<pairs.length; i++){
      var pair = pairs[i].split("=");
      cookies[(pair[0]+'').trim()] = unescape(pair.slice(1).join('='));
    }
    return cookies;
  }

  
    render() 
    {  

        return (  
            <div className="App blackClourV2">
                
                {this.state.showLoader ===true ?
                (
                    <PageLoader/> 
                )
                :(     
                    <div>
                        { (this.state.showLoginPage === true && this.state.notShowLoginPage === false) &&
                          <MobileLoginNJ/> 
                        }
                        {this.state.showNewCustomerPage === true &&
                          <AcquisitionLandingPage/> 
                        }
                        {this.state.showHomePage === true &&
                          <Home/> 
                        }
                        {this.state.showOrderPage === true &&
                          <Order/> 
                        }
                     
{/* 


                        {this.state.showHomePage ===true ?
                        (
                            <Home/> 
                        ):
                        (
                            <Order orderData={this.state.orderData} />

                        )} */}

                    </div>
                )
                }
            </div>
        );  
    }  
}  
  
export default  TrueCaller; 