import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import './../css/Style.css';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fontsource/poppins';
import flagCircle from '../images/india_flag_icon.png';
import UserService from '../services/UserService';
import { ToastContainer, toast } from 'react-toastify';
import LoggedInHeader from '../subComponents/LoggedInHeader';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const VendorDetailsChange = forwardRef((props, ref) => {
    const { changeVendorDetails, vendorBack } = props;
    const [accountInfo, setAccountInfo] = useState(JSON.parse(sessionStorage.getItem('orderDetails')));
    const [vendorCode, setVendorCode] = useState(sessionStorage.getItem('stg_vendor_code'));
    const [vendorName, setVendorName] = useState(sessionStorage.getItem('stg_vendor_name'));
    const [mobile, setMobile] = useState(sessionStorage.getItem('stg_vendor_mobile'));
    const [showErrorMsg, setShowErrorMsg] = useState(false);
    const [showSaveAndCancel, setShowSaveAndCancel] = useState(false);
    const [vendorUpdated, setVendorUpdated] = useState(false);
    const [vendorInfo, setVendorInfo] = useState([]);
    const [vendorId, setVendorId] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(true);
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
        cancel();
    };
    const handleShow = () => {
        setShow(true);
        setMobile(sessionStorage.getItem('stg_vendor_mobile'));
        setVendorCode(sessionStorage.getItem('stg_vendor_code'));
        setVendorName(sessionStorage.getItem('stg_vendor_name'));
    }

    useImperativeHandle(ref, () => ({
        handleShow
    }));
    
    const msg = (str) => toast.info(str, {
        position: toast.POSITION.TOP_CENTER
    });

    useEffect(() => {
        setMobile(sessionStorage.getItem('stg_vendor_mobile'));
        setVendorCode(sessionStorage.getItem('stg_vendor_code'));
        setVendorName(sessionStorage.getItem('stg_vendor_name'));
        
    }, []);

    const handleChangeVendorName = (event) => {
        setVendorName(event.target.value);
    };

    const handleChangeVendorCode = (event) => {
        setVendorCode(event.target.value);
    };

    const handleChangeVendorMobile = (event) => {
        const mobileNumber = event.target.value.replace(/\D/g, '');
        setMobile(mobileNumber);
        if (mobileNumber.length === 10) {
            getVendorInfo(mobileNumber, "handleChangeVendorMobile");
        } else {
            setVendorCode("");
            setVendorName("");
            setShowSaveAndCancel(false);
            // msg("Invalid Mobile Number!");
        }
    };

    const getVendorInfo = (mobileNumber, source) => {
        const accountId = JSON.parse(sessionStorage.getItem('orderDetails')).id;
        UserService.getVenderDetails(accountId, mobileNumber).then(res => {
            if (res.data.success) {
                sessionStorage.removeItem('vendorInfo');
                sessionStorage.setItem("vendorInfo", JSON.stringify(res.data));
                setVendorInfo(res.data);
                setVendorCode(res.data.employee_code);
                setVendorId(res.data.id);
                setVendorName(res.data.name);
                setMobile(res.data.mobile);
                setShowErrorMsg(false);
                setShowSaveAndCancel(source !== "cancel");
                let orderDetailsCh = sessionStorage.getItem('orderDetails');
                if (orderDetailsCh) {
                    orderDetailsCh = JSON.parse(orderDetailsCh);
                    orderDetailsCh.vendorCode = res.data.employee_code;
                    orderDetailsCh.vendorId = res.data.id;
                    orderDetailsCh.vendorMobile = res.data.mobile;
                    orderDetailsCh.vendorName = res.data.name;
                    sessionStorage.setItem('orderDetails', JSON.stringify(orderDetailsCh));
                }
            } else {
                setVendorCode("NA");
                setVendorName("NA");
                setShowErrorMsg(true);
                setShowSaveAndCancel(false);
            }
        }).catch(e => msg("Something went wrong"));
    };

    const cancel = () => {
        getVendorInfo(sessionStorage.getItem("stg_vendor_mobile"), "cancel");
        setVendorCode(sessionStorage.getItem("stg_vendor_code"));
        setVendorName(sessionStorage.getItem("stg_vendor_name"));
        setMobile(sessionStorage.getItem("stg_vendor_mobile"));
    };

    const saveVendorDetails = () => {
        if (vendorId !== "") {
            const accountId = JSON.parse(sessionStorage.getItem('orderDetails')).id;
            UserService.updateVendor(vendorId, accountId).then(res => {
                if (res.data.success) {
                    setShowErrorMsg(false);
                    setShowSaveAndCancel(false);
                    sessionStorage.setItem("vendorInfo", JSON.stringify(vendorInfo));
                    setVendorUpdated(true);
                    sessionStorage.setItem("stg_vendorUpdated", true);
                    sessionStorage.setItem("stg_vendor_mobile", mobile);
                    sessionStorage.setItem("stg_vendor_name", vendorName);
                    sessionStorage.setItem("stg_vendor_code", vendorCode);
                    msg("Vendor mobile number has been updated");
                    if(vendorBack){
                        changeVendorDetails();
                    }
                    
                } else {
                    setShowErrorMsg(true);
                    setShowSaveAndCancel(false);
                }
            }).catch(e => msg("Something went wrong"));
        }
        sessionStorage.setItem("stg_Venodr_Post_MobileNumber", mobile);
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            // backdrop="static"
            // keyboard={false}
            // size="md"  
            aria-labelledby="contained-modal-title-vcenter"  
            centered 
            id="model_custome_css"
            >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className="col-md-12 col-12">
                        <h5 className="rmc-heading mt-3 mb-0 text-center blackClourV2">Change Vendor Details</h5>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="col-md-12 col-12">
                    
                    <div className="scheme-box change-vendor-details">
                        <div className="row pt-2 pb-2">
                            <div className="col-md-12 form-group-model">
                                <div className="form-group">
                                    <label htmlFor="" className="vChangeLabel">Vendor Registered Number</label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text bg-white"><img src={flagCircle} className='flag fontNJ'/>&nbsp;+91</span>
                                        </div>
                                        <input className="form-control" name="mobile" value={mobile} type="tel" pattern="[0-9]*" onChange={handleChangeVendorMobile} />
                                    </div>
                                    {showErrorMsg &&
                                        <div className="error-msg text-center">
                                            <p className="mt-2 mb-0 error-msg red-text">Vendor not found</p>
                                            <p className="mb-0">Drop a mail to the BPO team for redemption For vendor maping</p>
                                            <p><strong>subscription@hindustantimes.com | 011-67764242 </strong></p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="row pt-2 pb-2">
                            <div className="col-md-6 form-group-model">
                                <div className="form-group">
                                    <label htmlFor="" className="vChangeLabel"> Vendor Name</label>
                                    <input type="text" value={vendorName} name="vendorName" onChange={handleChangeVendorName} className="form-control" disabled />
                                </div>
                            </div>
                            <ToastContainer />
                            <div className="col-md-6 form-group-model">
                                <div className="form-group">
                                    <label htmlFor="" className="vChangeLabel">Vendor Code</label>
                                    <input type="text" value={vendorCode} name="vendorCode" onChange={handleChangeVendorCode} className="form-control" disabled />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
            {showSaveAndCancel &&
                <div className="text-center vchange-btn">
                    {/* <a onClick={cancel} className="btn btn-light save-btn mr-lg-4">Cancel</a> */}
                    <a onClick={saveVendorDetails} className="btn btn-dark save-btn">CHANGE VENDOR</a>
                </div>
            }
            </Modal.Footer>
        </Modal>
    );
});

export default VendorDetailsChange;
