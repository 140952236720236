import React, { Component } from 'react'
import LoggedInHeader from '../subComponents/LoggedInHeader';
  // import './../css/Style.css';
import './../css/Checkout.css';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
 import { schemeDescription, duplicateImageFilter } from '../services/schemeTextFilter';
import UserService from '../services/UserService';
import { ToastContainer, toast } from 'react-toastify';
import ShowPaytmPaymentPage from './ShowPaytmPaymentPage';
import ShowRazorPayPaymentPage from './ShowRazorPayPaymentPage';
import PaymentSummary from '../subComponents/PaymentSummary';
import {getDataKeySame} from '../common/commonFuntion'
import PageLoader from '../subComponents/PageLoader';

let duplicateImageRemoved;
var printText = [];
var digitalText = [];
let compoPlan =[];
export default class PreAuthAllSchemes extends Component {
   defultAlert = (msg) => toast.warn(msg, {
      position: toast.POSITION.TOP_CENTER
   });
   constructor(props) {
      super(props);
      this.state = 
      {
         selectedSchems: '',
         schemePage: true,
         checkout: false,
         loading: false,
         payforScheme: [],
         comboSchemesShow: false,
         currentPlanPublication:'',
         currentPlanDuration: '',
         currentPlanPrice: '',
         currentPlanId: '',
         paymentOption: {
                           gateway: 'Razorpay',
                           offerCode: null,
                           offerDescription: null
                        },
          paymentOptionGateway:'Razorpay',
          paymentOptionOfferCode:null,
          paymentOptions: [],
          randomUUId: "",
          transToken: "",
          paytmPageShow: false,
          updateEmail: JSON.parse(sessionStorage.getItem('stg_preAuth_orderDetails')).email,
          mobile: JSON.parse(sessionStorage.getItem('stg_preAuth_orderDetails')).mobile,
         //  updateEmail: sessionStorage.getItem('preAuth_email'),
         //  mobile: sessionStorage.getItem('preAuth_mobile'),
          customerName:sessionStorage.getItem('preAuth_customerName'),
          selectedSchemsInfo: JSON.parse(sessionStorage.getItem('orderDetails')),
          showPaymentOffer: false,
          planNameOnSummary:'',
          ////// phase 2 //////////////
          allSchemsEndpoint: sessionStorage.getItem('preAuthAllSchemeEndpoint'),
          pageLoader:true,
          compoPlanSchems:[]
      }
   }

   onDismissRazorpay(){
      this.setState({ paytmPageShow: false });
   }

   updateShowPaymentOffer = (status) => {  
      this.setState({ showPaymentOffer: status });  
    }; 

    selectPaymentOfferL1= (value) => {  
      this.setState({ paymentOption: {gateway: value[0], offerCode: value[1]}, paytmPageShow: false });
    }

   paymentDoneSuccess() 
   {
      let couponCodeValueforApi="";
      if (this.state.paymentOption.gateway) 
      {
         
         this.setState({ loading: true });
         UserService.paymentTransaction(this.state.randomUUId, this.state.currentPlanPrice,
            this.state.mobile, this.state.currentPlanId, sessionStorage.getItem('orderId'), this.state.paymentOption.gateway,this.state.updateEmail,couponCodeValueforApi.trim(),null).then(response => 
              {

         // UserService.paymentTransaction(this.state.randomUUId, this.state.currentPlanPrice, this.state.mobile, this.state.currentPlanId,sessionStorage.getItem('orderId'), this.state.paymentOption.gateway, this.state.updateEmail,"").then(response => {
               if (response.success) 
               {

                  this.setState({ transToken: response.txnToken });
                  this.setState({ mId: response.mid });
                  sessionStorage.setItem("mId",response.mid);
                  this.setState({ loading: false });
                  this.setState({ paytmPageShow: true });
               }
               else 
               {
                  this.defultAlert(response.message);
                  this.setState({ loading: false });

               }
            }).catch(err => {
               this.defultAlert("Failed! Check your network")
               this.setState({ loading: false });
            })
         } 
         else 
         {

            this.setState({ PaymentPage: true });
            this.setState({ paytmPageShow: false });
            this.defultAlert("Please choose payment option");
         }
   }

   genrateNumberUUId() 
   {
      const length = 15;
      const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
      var result = '';
      for (var i = length; i > 0; --i)
         result += chars[Math.round(Math.random() * (chars.length - 1))];
      this.setState({ randomUUId: result });
      sessionStorage.setItem("linkId", result);
   }

   componentDidMount() 
   {
      this.getSelectedSchems();
      sessionStorage.setItem("orderDetails",sessionStorage.getItem('stg_preAuth_orderDetails'))
   }

   getSelectedSchems()
   {
      sessionStorage.setItem("preAuthAllSchemeToken",this.props.preAuthToken);
      
      let dataImportDepo = [];

      setTimeout (async () =>{
         dataImportDepo = await getDataKeySame(this.state.allSchemsEndpoint)
            if (dataImportDepo !== "") 
            {
               this.setState({selectedSchems : dataImportDepo.schemes[0]})
               this.setState({selectedPublication : dataImportDepo.schemes[0].currentSchemes[0].publications})
               this.setState({currentSchemesList : dataImportDepo.schemes[0].currentSchemes})
               this.setState({currentComboSchemesList : dataImportDepo.schemes[0].comboSchemes})
               
               this.setState({compoPlanSchems:  dataImportDepo.schemes[0].comboSchemes});
               if (this.state.selectedSchems.comboSchemes.length !== 0) 
               {
                  this.setState({ comboSchemesShow: true });


               }
               this.getExitingPlan();
               this.getCompoOtherPlan();
               this.genrateNumberUUId();
               this.setState({compoPlanSchems:  compoPlan});
               this.setState({pageLoader : false});

            }
      },800)
   }

   getPlanNameOnSummary(publicationName)
   {
      


      if(publicationName==="HT")
      {
         this.setState({ planNameOnSummary: "Hindustan Times" });
      }
      else if(publicationName==="HH")
      {
         this.setState({ planNameOnSummary: "Hindustan" });

      }
      else if(publicationName==="BP")
      {
         this.setState({ planNameOnSummary: "Mint" });

      }
      else if(publicationName==="LH")
      {
         this.setState({ planNameOnSummary: "Live Hindustan" });

      }
      else if(publicationName==="HT;HH")
      {
         this.setState({ planNameOnSummary: "Hindustan Times:Hindustan" });

      }
      else
      {
         this.setState({ planNameOnSummary: "Hindustan Times Newspaper"});

      }
   }


   getExitingPlan()
   {
      

      if (this.state.selectedSchems.currentSchemes.length !== 0) 
      {
         this.state.selectedSchems.currentSchemes.map((schemes, i) => 
         (
            this.setState({ currentPlanDuration: schemes.duration }),
            this.setState({ currentPlanPrice: schemes.price }),
            this.setState({ currentPlanPublication: schemes.publications }),
            this.setState({ currentPlanId: schemes.id }),
             this.getPlanNameOnSummary(schemes.publications)     
         ))
      }
      else
      {
            this.setState({ currentPlanDuration: this.state.selectedSchems.comboSchemes[0].duration })
            this.setState({ currentPlanPrice: this.state.selectedSchems.comboSchemes[0].price })
            this.setState({ currentPlanPublication: this.state.selectedSchems.comboSchemes[0].publications })
            this.setState({ currentPlanId: this.state.selectedSchems.comboSchemes[0].id })
            this.getPlanNameOnSummary(this.state.selectedSchems.comboSchemes[0].publications)     
      }    

      
   }

   getCompoOtherPlan()
   {
      if (this.state.selectedSchems.currentSchemes.length !== 0) 
      {
         { this.state.selectedSchems.comboSchemes.map((schemes, i) => (

            compoPlan.push({'id':schemes.id, 'publications': schemes.publications, 'duration':schemes.duration,'price':schemes.price})
         ))}
      }
      else
      {
         { this.state.selectedSchems.comboSchemes.map((schemes, i) => (

            compoPlan.push({'id':schemes.id, 'publications': schemes.publications, 'duration':schemes.duration,'price':schemes.price})
         ))}

         compoPlan.shift()

      }
   }

   clickOnSelect(e)
   {
      let newArray=[];
      newArray = compoPlan.find(el => el.id === e.target.id);
      compoPlan.push({id:this.state.currentPlanId, 'publications': this.state.currentPlanPublication, 'duration':this.state.currentPlanDuration,'price': this.state.currentPlanPrice})
      this.setState({ currentPlanDuration: newArray.duration })
      this.setState({ currentPlanPrice: newArray.price })
      this.setState({ currentPlanPublication: newArray.publications })
      this.setState({ currentPlanId: newArray.id })
      compoPlan.splice(compoPlan.findIndex(a => a.id === e.target.id) , 1)
      this.setState({compoPlanSchems:  compoPlan});
      this.getPlanNameOnSummary(newArray.publications)
   }


  redirectToHome() 
  {
      window.location = '/';
  }

   goBackPage() {
      this.setState({ schemePage: true });
      this.setState({ checkout: false });
   }
   click = () => 
   {
      this.props.schemeCallBack();
   }

   render() 
   {

      const { loading } = this.state;
      printText = [];
      digitalText = [];
      return (
         <section className="">
            {this.state.schemePage &&
            <div>
               <div className="container">
                  {this.state.pageLoader && 
                     <PageLoader/> }               
               </div>   
               <div className="absolute-center">
                  <div className="container">
                     <div className="row justify-content-center">
                        <div className="col-md-8">
                           <h5 className="mt-lg-2 mt-5 blackClourV2">Dear {this.props.preUserName},  Your subscription is about to expire. Kindly renew from the schemes below</h5>
                           <br></br>
                           <div className="row orderCh">
                              <div className="col-md-7 blackClourV2">
                                 <div className="current-schemeCh">
                                    <div>
                                       <h6>You are renewing to</h6>
                                       {(() => 
                                       {
                                          duplicateImageRemoved = duplicateImageFilter(this.state.currentPlanPublication);
                                          var filteredText = schemeDescription(this.state.currentPlanPublication);
                                          printText = filteredText[0];
                                          digitalText = filteredText[1];
                                       })()}
                                       {
                                          duplicateImageRemoved.map(function (item, i) 
                                          {
                                              return [<div className={item.classNameCSS} key={i + 1}><img src={item.image} alt="logo" key={i} className="img-fluid mr-3 mt-2" /> </div>]
                                          })
                                       }
                                    </div>
                                    <div id="details-box">
                                       <p>Daily newspaper delivery </p>
                                    </div><hr></hr>
                                    <div id="duration-box">
                                    <p>{Math.floor(this.state.currentPlanDuration) || "NA"} Months Plan</p>
                                    </div>
                                 </div>
                                 <div className="other-scheme mt-4">
                                    <div className="header__center">Other Plans</div>   
                                       { this.state.compoPlanSchems.map((schemes, i) => (
                                          <div className="row mt-2">
                                             <div className="col-4 publication-details">
                                                {(() => {
                                                   duplicateImageRemoved = duplicateImageFilter(schemes.publications);
                                                   var filteredText = schemeDescription(schemes.publications);
                                                   printText = filteredText[0];
                                                   digitalText = filteredText[1];

                                                })()}
                                                {
                                                   duplicateImageRemoved.map(function (item, i) 
                                                   {
                                                      if(i==0)
                                                      {
                                                         return [<div className={item.classNameCSS} key={i + 1}><img  src={item.image} alt="logo" key={i} className="img-fluid mr-3 mt-2" /> </div>]
                                                      }
                                                      else if (i>0)
                                                      {
                                                         return [<div className={item.classNameCSS} key={i + 1}><h5 className="planAddition">+</h5><img  src={item.image} alt="logo" key={i} className="img-fluid mr-3 mt-2" /> </div>]

                                                      }
                                                   })
                                                }
                                                <p>{Math.floor(schemes.duration) || "NA"} Months Plan</p>
                                             </div>
                                             <div className="col-4 text-center price">
                                                <p style={{marginTop: "10px"}}><i className="fa fa-inr"></i>{schemes.price}</p>
                                             </div>
                                             <div className="col-4 mt-2">
                                                <button className="select-btn btn" onClick={this.clickOnSelect.bind(this)} id={schemes.id}>Select</button>
                                             </div>
                                          </div>
                                       ))}

                                 </div>
                              </div>
                              <div className="col-md-5 summaryy-details">
                                 {/* <p className="showBtn"></p>
                                 <div className="order-details">
                                    <h5>Summary</h5>
                                    <div id="details-box" className="blackClourV2">
                                       <p>{this.state.planNameOnSummary}</p>
                                    </div>
                                    <table className="table border-0 mb-0">
                                       <tbody>
                                          <tr className="total-final-price  ">
                                             <th className="text-left">
                                                <p className="mb-0  ">Sub Total (inc. GST): </p>
                                             </th>
                                             <td className="text-center"></td>
                                             <td className="text-right">
                                                <div className=" final-price  ">
                                                   <p><i className="fas fa-rupee-sign  "></i>{this.state.currentPlanPrice}</p>
                                                </div>
                                             </td>
                                          </tr>
                                       </tbody>
                                    </table>
                                    <a onClick={() => this.updateShowPaymentOffer(true)} className="paymentLinkCh"><i className="fa fa-tag fa-lg fa-rotate-90" style={{fontSize: "16px"}}></i>  <u>Payment Offers</u></a>

                                 </div>
                                 <PaymentOffer  
                                          showModalPopup={this.state.showPaymentOffer}  
                                          onPopupClose={this.updateShowPaymentOffer}  
                                          onSelectPayment={this.selectPaymentOffer}
                                 ></PaymentOffer>   */}
                                       <PaymentSummary
                                    planNameOnSummary={this.state.planNameOnSummary}
                                    currentPlanPrice={this.state.currentPlanPrice}
                                    onSelectPaymentL1={this.selectPaymentOfferL1}
                                    
                                 />
                                 <div className="bottom-tab">
                                    <div className="total-price">
                                       <p className="text-center blackClourV2">Total (inc. GST)    :    Rs. {this.state.currentPlanPrice}</p>
                                    </div>
                                    <div className="checkout mt-3 text-center"><button className="btn checkout-btn" onClick= {this.paymentDoneSuccess.bind(this)} data-toggle="modal" data-target="#myModall">CHECKOUT</button></div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>  
            </div>
            }
            {/* {this.state.showPaymentOffer && <PaymentOffer/>} */}
            {this.state.paytmPageShow && this.state.paymentOption.gateway && (this.state.paymentOption.gateway === "Paytm" ? <ShowPaytmPaymentPage txnToken={this.state.transToken} /> : 
            <ShowRazorPayPaymentPage txnToken={this.state.transToken} mobile={this.state.mobile} email={this.state.updateEmail} 
            name={this.state.customerName} price={this.currentPlanPrice} onDismissRazorpay={this.onDismissRazorpay.bind(this)} />)
            }
       
         </section>

      );
   }
}


