import axios from 'axios';
import { createBrowserHistory } from 'history';
const history = createBrowserHistory();

const authAxios = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        'Content-Type': 'application/json'
    }

})

// authAxios.interceptors.response.use(
//     response => response,
//     error => {
//         let errorMessage = 'Unknown error occurred';
//         if (error.message === 'Network Error') {
//             errorMessage = 'Network Error - make sure API server is running.';
//         } else if (error.code === 'ECONNABORTED') {
//             errorMessage = 'A timeout happened on the URL: ' + error.config.url;
//         } else if (error.response) {
//             errorMessage = `Error: ${error.response.status} - ${error.response.statusText}`;
//         }
//         // console.log("errorMessage ============= ", errorMessage);
//         history.push({
//             pathname: '/error',
//             state: { errorMessage }
//         });
//       return Promise.reject(error);
//     }
// );

class AuthService {
    async getAuth(username, password) {

        return await  authAxios.post("/apis/subscription/v1/auth", {
            username,
            password
        }).then(response => {
            if (response.data.token) 
            {
                sessionStorage.setItem("AuthInfo", response.data.token);
            }
            return response.data;
        });
    }



}

export default new AuthService();