import React, { useState, useEffect, Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import './../css/Checkout.css';
import complaintPopupIcon from '../images/complaint-management/complaint-management-popup.svg';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function PopupComplaintSuccess({ showModalPopup, onPopupClose, showPopupMsg }) {
    const [showModal, setShowModal] = useState(false);

    // useEffect(() => {
    //     if (showModalPopup) {
    //     }
    // }, [showModalPopup]); // Fetch issues only when the modal opens

    const handleClose = () => {
        onPopupClose(false);
    };

    const isShowModal = (status) => {
        handleClose();
        setShowModal(status);
    };

    const redirectToComplaint = () => {
        window.location.href = '/query-management' 
    }

    return (
        <>
            <Modal
                show={showModalPopup}
                onHide={handleClose}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                id="model_custome_css"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="blackClourV2 text-center"></Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ padding: '0px' }}>
                        <div className="complaint_box_design">
                            <div className="col-md-6 popupImage" style={{ paddingLeft: '0px' }}>
                                <img src={complaintPopupIcon} alt="Help & Support" />
                            </div>

                            <div className="col-md-6 popupComplaint">
                                <p className="popupMsgComplaint">{showPopupMsg || ''}</p>
                                <div className="button-complaint form-complaint">
                                    <button type="submit" className="btn" onClick={redirectToComplaint}>
                                        Help & Support
                                    </button>
                                </div>
                            </div>
                                
                        </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default PopupComplaintSuccess;
