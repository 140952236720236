import React, { Component, Fragment } from 'react';  
import { Modal } from 'react-bootstrap';  
import './../css/Checkout.css';
import phone from '../images/phone.svg';
import mail from '../images/mail.svg';

class PopupContactus extends Component {  
    constructor(props) {  
        super(props);  
        this.state = {  
            showModal: false,
        };  
    }  
    
   componentDidMount() 
   {
   }
  
    isShowModal = (status) => {  
        this.handleClose();  
        this.setState({ showModal: status });  
    }  
  
    handleClose = () => { 
        sessionStorage.setItem("stg_mo_redirectFrom","Close the Alert on T&C")
        this.props.onPopupClose(false);  
    }  

  
    render() 
    {  
        return (  
            <Fragment>  
                <Modal show={this.props.showModalPopup} onHide={this.handleClose}  
                    size="sm"  
                    aria-labelledby="contained-modal-title-vcenter"  
                    centered 
                    id="model_custome_css" 
                >  
                        <Modal.Header closeButton>
                            <Modal.Title  className="blackClourV2 text-center"></Modal.Title>
                        </Modal.Header>
					<Modal.Body>  
                        <div>
                            <div className="contact_box_design">
                                <div className="col-xl-12 text-center">
                                    <h4>CONTACT US</h4>
                                    <hr style={{'border-top': '1px solid #04a7d0'}}/>
                                    <p>
                                    <img src={phone} width="40" height="40"/>
                                    </p>
                                    <p class="text-center">Mumbai: <a href="tel:+022-67764242">022-67764242</a></p>
                                    <p class="text-center">Delhi: <a href="tel:+011-60004242">011-60004242</a></p>
                                    <p class="text-center">Punjab: <a href="tel:+91 7009989177">+91 7009989177</a></p>
                                    <p>
                                    <img src={mail} width="40" height="40"/>
                                    </p>
                                    <p class="text-center cmfont">Mumbai Email Id: <a href="mailto:subscription@hindustantimes.com">subscription@hindustantimes.com</a></p>
                                    <p class="text-center cmfont">Delhi Email Id: <a href="mailto:customerservice@hindustantimes.com">customerservice@hindustantimes.com</a></p>
                                    <p class="text-center cmfont">Punjab Email Id: <a href="mailto:circulationcustomercare@hindustantimes.com">circulationcustomercare@hindustantimes.com</a></p>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>  
                </Modal >  
            </Fragment > 
        );  
    }  
}  
  
export default (PopupContactus); 