import React, {useState} from 'react';
import './../css/Style.css';
import './../css/complaint-management.css';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fontsource/poppins';
import './../css/StyleV3.css';
import './../css/StyleV2.css';
import './../css/order.css';
import OrderCreated from "../images/Orders/Order_Created.svg";
import SubscriptionReceivedActive from "../images/Orders/Subscription-Received-Active.svg";
import RenewalChannels from "../images/Orders/Renewal_channels.svg";
import SubscriptionApproved from "../images/Orders/Subscription-Approved.svg";
import SubscriptionApprovedActive from "../images/Orders/Subscription-Approved-Active.svg";
import PaymentStatus from "../images/Orders/Payment-Status.svg";
import PaymentStatusActive from "../images/Orders/Payment-Status-Active.svg";
import CouponsIssued from "../images/Orders/Coupons-Issued.svg";
import CouponsIssuedActive from "../images/Orders/Coupons-Issued-Active.svg";
import CouponsDispatch from "../images/Orders/Coupons-Dispatch.svg";
import CouponsDispatchActive from "../images/Orders/Coupons-Dispatch-Active.svg";
import Moment from 'moment';
// import { Tooltip, Button } from '@material-ui/core';
import Popper from '@material-ui/core/Popper';
import { Modal } from 'react-bootstrap';
import { withStyles } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';

// const CustomTooltip = withStyles((theme) => ({
//    tooltip: {
//       background: "#0084A5 0% 0% no-repeat padding-box",
//       boxShadow: "0px 3px 6px #00000029",
//       maxWidth: 350,
//    },
//    arrow: {
//      color: '#0084A5', // Arrow color
//    },
//  }))(Tooltip);

const userInfo = JSON.parse(sessionStorage.getItem('orderDetails')) || {
    ciName: '',
    ciCode: '',
    ciMobile: '',
    centerName: ''
};

function OrderDetails ({ isOpenDetail, onCloseDetail, orderDetail, orderKey=0 }) {
    const stageInfo = {
        subscriptionReceived: {
            imgSrc: {
                'Inactive': OrderCreated,
                'Active': SubscriptionReceivedActive,
            },
            text: 'Subscription Received',
        },
        
        paymentStatus: {
            imgSrc: 
            {
                'Inactive': PaymentStatus,
                'Active': PaymentStatusActive,
            },
            text: 'Payment Status',
        },
        startDate: {
            imgSrc: 
            {
                'Inactive': SubscriptionApproved,
                'Active': SubscriptionApprovedActive,
            },
            text: 'NewsPaper Start Date',
        },
        couponDetail: {
            imgSrc: 
            {
                'Inactive': CouponsIssued,
                'Active': CouponsIssued,
            },
            text: 'Coupons Detail',
        }
    };

    const initialOpenTooltipsState = {}; // Create an object to manage each tooltip's open state

    // Assuming you have an array of keys or stageInfo keys
    Object.keys(stageInfo).forEach((key) => {
        initialOpenTooltipsState[key] = false; // Initialize each key to false
    });
    
    const [openTooltips, setOpenTooltips] = useState(initialOpenTooltipsState);
    const [openPopper, setOpenPopper] = useState({}); // State to manage which Popper is open
    const [touchStart, setTouchStart] = useState(false);

    const handleTouchStart = (key, event) => {
        setTouchStart(true); // Mark touchStart as true to prevent onClick
        handleClick(key, event); // Call your existing click handler
    };
    const handleMouseEnter = (key, event) => {
        const anchor = event.currentTarget;
        setOpenPopper((prev) => ({
            ...prev,
            [key]: anchor instanceof HTMLElement ? anchor : null,
        }));
    };
    
    const handleClick = (key, event) => {
        event.stopPropagation();
        if (touchStart) {
            setTouchStart(false); // Reset touchStart to false after the first touch event
            return; // Prevent the onClick from firing after touch
        }
        const anchor = event.currentTarget;
        setOpenPopper((prev) => ({
            ...prev,
            [key]: prev[key] ? null : anchor instanceof HTMLElement ? anchor : null,
        }));
    };
    
    const handleMouseLeave = (key) => {
        setOpenPopper((prev) => ({
            ...prev,
            [key]: null, // Clear anchor on leave
        }));
    };

    if (!isOpenDetail) return null;

    var publicationNameArr = {'HT':'Hindustan Times','HH': 'Hindustan Hindi','LM':'Live Mint','Mint':'Mint'}

    let letter = "A"; // Ensure `i` is defined in scope
    let isStageEmpty = false;
    let couponType = "";
    let button = "";

    if (orderDetail) {
        const i = orderKey; // Assuming 'i' is part of your logic
        letter = String.fromCharCode(65 + i);

        // isStageEmpty = orderDetail.stage && Object.keys(orderDetail.stage).length === 0;
        isStageEmpty = !orderDetail.stage || (typeof orderDetail.stage === 'object' && Object.keys(orderDetail.stage).length === 0);

        if (orderDetail.couponPreference !== null) {
            if (orderDetail.couponPreference.includes("M")) {
                couponType = "Digital Coupon";
            } else {
                couponType = "Physical Coupon";
            }
        } else {
            couponType = "";
        }
    }

    return (
        <>
            <Modal
                show={isOpenDetail}
                onHide={onCloseDetail}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                id="order-details"
                key={orderDetail.id}
            >
                <Modal.Header closeButton>
                    {/* <Modal.Title className="CITitle">Order Details</Modal.Title> */}
                </Modal.Header>
                <Modal.Body onClick={(e) => e.stopPropagation()} style={{ padding: '0px' }}>
                        <div className="order-management" style={{ color: 'black' }}>
                            {orderDetail ? (
                                <div className="card">
                                    <div className="card-body">
                                        <div className="order-item">
                                            <div className="type">
                                                <span variant="body1">
                                                    {couponType}
                                                </span>
                                            </div>

                                            <div className="order-first-first">
                                                <span className="mr-3">Order {letter}</span>
                                                <span className={`Dot ${orderDetail.status ? orderDetail.status + 'Dot' : 'greenDot'}`}></span>
                                                <span className="last">{orderDetail.status}</span>
                                            </div>
                                            <div className="order-first-second mb-3 mt-2">
                                                <div className="order-publication-div">
                                                    <span className="fSec-head">Publication:</span>
                                                    <span className="fSec-title">{publicationNameArr[orderDetail.scheme.publications] ? publicationNameArr[orderDetail.scheme.publications] : orderDetail.scheme.publications}</span>
                                                </div>
                                                <span className="separator"></span>
                                                <div className="order-plan-div">
                                                    <span className="fSec-head">Plan:</span>
                                                    <span className="fSec-title">{Math.floor(orderDetail.scheme.duration) || 'NA'} Months</span>
                                                </div>
                                                {(orderDetail.order_start_date && orderDetail.order_end_date) && (
                                                    <>
                                                    <span className="separator"></span>
                                                    <div className="order-expiry-div">
                                                        <span className="fSec-head">Dates:</span>
                                                        <span className="fSec-title">{orderDetail.order_start_date ? Moment(orderDetail.order_start_date).format('DD.MM.YYYY') : 'NA'} - {orderDetail.order_end_date ? Moment(orderDetail.order_end_date).format('DD.MM.YYYY') : 'NA'}</span>
                                                    </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        {
                                            !isStageEmpty &&
                                            <div>
                                                <div className="order-second">
                                                    {Object.entries(orderDetail.stage).map(([key, value]) => {
                                                    let isActive = '';
                                                    let title = '';
                                                    let imgStatus = 'Inactive';
                                                    let couponDispatchDetail = '';
                                                    if (typeof value === 'boolean' && value) {
                                                        isActive = 'active';
                                                        imgStatus = 'Active';
                                                    }else if (value && typeof value === 'string' && key == 'startDate') {
                                                        title = `Start Date: ${value}`;
                                                    }else if (Array.isArray(value) && value.length > 0 && (value[0].status || value[0].status === 'Success')) {
                                                        isActive = 'active';
                                                        imgStatus = 'Active';
                                                        if (key === 'paymentStatus') {
                                                            title = `status: ${value[0].status} | transactionId: ${value[0].transactionId} | mode: ${value[0].mode}`;
                                                        }
                                                    } else if (typeof value === 'object' && value !== null && (value.status || value.status === 'Success')) {
                                                        isActive = 'active';
                                                        imgStatus = 'Active';
                                                        if (key === 'paymentStatus') {
                                                            title = `status: ${value.status} | transactionId: ${value.transactionId} | mode: ${value.mode}`;
                                                        }
                                                        
                                                    }else if (typeof value === 'object' && value !== null && key === 'couponDetail') {
                                                        if (key === 'couponDetail') {
                                                            couponDispatchDetail = '';
                                                        
                                                            if (value.couponDispatchDetail && typeof value === 'object' && value.couponDispatchDetail !== null) {
                                                                const dispatchDetails = Object.entries(value.couponDispatchDetail)
                                                                    .filter(([_, disValue]) => disValue !== null)  // filter out null values
                                                                    .map(([disKey, disValue]) => `${disKey}: ${disValue}`) // format the details
                                                                    .join(' | '); // join them with " | "
                                                        
                                                                // Only add Delivery Status if there are details
                                                                if (dispatchDetails) {
                                                                    couponDispatchDetail = `| Delivery Status: ( ${dispatchDetails} )`;
                                                                }
                                                            }
                                                        }
                                                        
                                                        title = `Type: ${value.couponType} | Number: ${value.couponNumber} ${couponDispatchDetail}`;
                                                        
                                                    }

                                                    return (
                                                        <div key={key} className={`order-second-first ${isActive}`} data-id={key + " - " + value}>
                                                            <img
                                                                onClick={(event) => handleClick(key, event)} // Toggle Popper on click
                                                                onMouseEnter={(event) => handleMouseEnter(key, event)} // Handle hover to open Popper
                                                                onMouseLeave={() => handleMouseLeave(key)} // Handle hover out to close Popper
                                                                onTouchStart={(event) => handleTouchStart(key, event)}
                                                                className="img"
                                                                src={stageInfo[key].imgSrc['Inactive']}
                                                                alt={stageInfo[key].imgSrc['Inactive']}
                                                            />
                                                            {openPopper[key] && title && (
                                                                <Popper
                                                                    open={Boolean(openPopper[key])}
                                                                    anchorEl={openPopper[key] instanceof HTMLElement ? openPopper[key] : null}
                                                                    placement="top"
                                                                    modifiers={{
                                                                        preventOverflow: {
                                                                            enabled: true,
                                                                            options: {
                                                                                padding: 8,
                                                                            },
                                                                        },
                                                                        offset: {
                                                                            enabled: true,
                                                                            offset: '0, 10', // Adjust the offset if needed
                                                                        },
                                                                    }}
                                                                    style={{
                                                                        zIndex: 1300,
                                                                        top: 54 // Set the z-index directly here
                                                                    }}
                                                                >
                                                                    <div className="tooltip-content">
                                                                        {title}
                                                                    </div>
                                                                </Popper>
                                                            )}

                                                            <span className="text">{stageInfo[key].text}</span>
                                                        </div>
                                                    );
                                                    })}

                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            ) : (
                                <p>No Center Details Available!</p>
                            )}
                        </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default OrderDetails;
