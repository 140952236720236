import axios from 'axios';
import authHeader from '../services/auth-header';
const ENDPOINTS = 'apis/subscription/v1/login';

const USER_API_URL = process.env.REACT_APP_BASE_URL;

// const authAxios = axios.create({
//     baseURL: USER_API_URL,
//     headers: {
//         Authorization: 'Basic YWlydGVsX2ludGVncmF0aW9uX3VzZXI6aHRfYWlydGVsQDEyMw==',
//         'Content-Type': 'application/json'
//     }
// })

axios.interceptors.request.use(
    config => {
        config.baseURL = USER_API_URL;
        config.headers["Content-Type"] = "application/json"
        config.headers["Authorization"] = "Bearer " + sessionStorage.getItem("AuthInfo");
        return config;
    },
    error => {
        Promise.reject(error);
    }
);

class UserService {

     generateOtp (mobileNum, source, utm_source, customer_mobile, fresh_user, onBooking)
     {
           return axios.post( `/apis/subscription/v1/login/generate-otp`, {
            mobileNum,
            source,
            utm_source,
            customer_mobile,
            fresh_user,
            onBooking
            
        })
            .then(response => {
                return response.data;
            }).catch(error => {
                console.log('error', error)
              });
    }
    generateFreshUserOtp (mobileNum, source,fresh_user) 
    {
          return axios.post( `/apis/subscription/v1/login/generate-otp`, {
           mobileNum,
           source,
           fresh_user
           
       })
           .then(response => {
               return response.data;
           });
   }

   verifiyAcqFreshUserOtp (mobile, source,otp,scheme_id,pincode) 
   {
         return axios.post( `/apis/subscription/v1/login/fresh-user`,{
          mobile,
          source,
          otp,
          scheme_id,
          pincode
          
      })
          .then(response => {
              return response.data;
          });
    }

    addDeliveryEmailAdress(order_id, flat_no,floor,block_or_street,city,state,pin_code,locality_non_master,society_non_master,email,customer_name,initiator) 
    {
          return axios.post( `/apis/subscription/v1/orders/update-address`,{
            order_id,
            flat_no,
            floor,
            block_or_street,
            city,
            state,
            pin_code,
            locality_non_master,
            society_non_master,
            email,
            customer_name,
            initiator
       })
           .then(response => {
               return response.data;
           });
     }

     paymentTransaction(link_id, price, mobile, scheme_id, order_id, gateway,user_email,scratch_card_code,lead_id,utm_source = null)
     {
           return axios.post( `/apis/subscription/v1/reNewScheme/payments/fetchAllPayment`,{
            link_id,
            price,
            mobile,
            scheme_id,
            order_id,
            gateway,
            user_email,
            scratch_card_code,
            lead_id,
            utm_source
        })
            .then(response => {
                return response.data;
            });
      }

    verifyAcqFreshPinCodeEligibility(pincode,schemeId,source) 
    {
        return axios.get( `/apis/subscription/v1/home/schemes/is-product-eligible?pincode=`+pincode+`&schemeId=`+schemeId +`&source=`+source)
    }

    async getSSOTrueCaller(token) 
    {
        return await axios.get( `/apis/subscription/v1/login/sso-user-data?token=`+token)
    }

    getWhatsappNotification(mobileNumber) 
    {
        return axios.get( `/apis/subscription/v1/whatsapp-integration?method=OPT_IN&mobile=`+mobileNumber)
    }

    verifyScratchCouponDetails(couponCode,schemeId,mobileNum) 
    {
        return axios.get( `apis/subscription/v1/reNewScheme/payments/get-scratch-card-details?code=`+couponCode+`&scheme_id=`+schemeId+`&mobile=`+mobileNum)
    }

    verifyPreAuthRedirection(unique_code) 
    {
        return axios.get( `/apis/subscription/v1/auto-login?unique_code=` + unique_code);
    }

    verifiyOtp(otp, mobile) {
        const source = "SSP";
        return axios.get( `/apis/subscription/v1/login/verifyOtp?source=` + source + `&mobile=` + mobile + `&otp=` + otp);
    }
    getAllowedSchemes(salesOrg, orderCode, accountId) {
        return axios.get( `/apis/subscription/v1/home/schemes/get-allowed-schemes?sales_org=` + salesOrg + `&scheme=` + orderCode + `&accountId=` + accountId);

    }

   

    getPaymentByLinkId(linkId) {
        return axios.get( "/apis/subscription/v1/reNewScheme/payments/getPaymentByLinkId/" + linkId);

    }
    fetchOrders(accountId) {

        return axios.get( "/apis/subscription/v1/orders/get-all-orders/" + accountId);

    }

    acqSubscriptionDetails() {
        return axios.get( "/apis/subscription/v1/fresh?publication=HT");
    }
    couponDetails(accountId, publication, source) {
        return axios.get( "/apis/subscription/v1/coupons/get-all-coupons/" + accountId + "/" + publication + "/" + source);
        // return axios.get("http://localhost:8083/apis/subscription/v1/coupons/get-all-coupons/0011s00000A2CMmAAN/HH");
    }
    updateEmail(email, mobile) {
        return axios.patch( `/apis/subscription/v1/update-email?email=` + email + `&mobile=` + mobile);
    }

    fetchAccountInfo(mobile) {
        return axios.get( `/apis/subscription/v1/get-account-details?mobile=` + mobile);
    }

    fetchPaymentGatewayInfo(territoryId, publication, paymentMode, gateway) {
        return axios.get( `/apis/subscription/v1/reNewScheme/payments/getGatewayInfo/` + territoryId + `/` + publication + `/` + paymentMode + `/` + gateway);
    }
    redeemedCoupons(vendorId, couponInfo, source, mobile,vendorUpdated, accountId,otp) {
        return axios.post( `/apis/subscription/v1/coupons/redeem-coupons/`, ({
            "vendor_id": vendorId,
            "source": source,
            "mobile": mobile,
            "coupons": couponInfo,
            "vendor_updated": vendorUpdated,
            "account_id": accountId,
            "redemption_source": "SSP",
            "otp": otp
        }));
    }

    releaseCoupons(source,mobile,otp,couponInfo,vendorId,is_released) 
    {
        return axios.post( `/apis/subscription/v1/coupons/release-coupons/`, ({
            "source": source,
            "mobile": mobile,
            "otp": otp,
            "coupons": couponInfo,
            "vendor_id":vendorId,
            "is_released":is_released
           
        }));
    }

    updateVendor(vendorInfo,accountInfo) 
    {
        return axios.post( `/apis/subscription/v1/external-partner/update-customer-vendor/`, ({
            "vendor_id": vendorInfo,
            "account_id": accountInfo
        }));
    }

    getLocalities(salesOrg, searchTerm) {
        return axios.get(`/apis/subscription/v1/get-localities?sales_org=` + salesOrg + `&city=` +`&search_key=`+ searchTerm);
    }

    getSocieties(localityId) {
        return axios.get( `/apis/subscription/v1/get-societies?locality_id=` + localityId);
    }

    updateAddress(addressDetails, accountId, description) {
        return axios.post(`/apis/subscription/v1/case/create-case/`, ({
            "source":"SSP",
            "new_address": addressDetails,
            "account_id": accountId,
            "description": description
        }));
    }

    createComplain(payload) {
        return axios.post(`/apis/subscription/v1/case/create-complain`, ({
            "typeId": payload.typeId,
            "subTypeId": payload.subTypeId,
            "accountId": payload.accountId,
            "ciId": payload.ciId,
            "description": payload.description,
            "orderId": payload.orderId
        }));
    }

    getAllComplaints(account_id) {
        return axios.get(`/apis/subscription/v1/case/complain-listing/${account_id}`);
    }
    getCaseTyps() {
        return axios.get(`/apis/subscription/v1/case/case-picklist`);
    }
    
    getVenderDetails(accountId, mobile) {
        return axios.get( `/apis/subscription/v1/external-partner/change-vendor/` + accountId + `/` + mobile + `/` + "SSP");

    }
    createOrder(txnId, amountScheme, schemCode, accountId, orderId, name, emailId, mobileNum, salesOrg) {
        // const dt = JSON.stringify({
        //     "data": {
        //         "customer_name": name,
        //         "mobile": mobile,
        //         "email": email,
        //         "scheme": schemCode,
        //         "source": "SSP",
        //         "transaction_id": txnId,
        //         "amount": amount,
        //         "sales_org": salesOrg,
        //         "is_address_filled": false,
        //         "order": orderId,
        //         "account": accountId
        //     }
        // });

        const customer_name = name;
        const mobile = mobileNum;
        const email = emailId;
        const scheme = schemCode;
        const source = "SSP";
        const transaction_id = txnId;
        const amount = amountScheme;
        const sales_org = salesOrg;
        const is_address_filled = false;
        const order = orderId;
        const account = accountId;
        return axios.post( `/apis/subscription/v1/order/create-order`,
            {
                customer_name,
                mobile,
                email,
                scheme,
                source,
                transaction_id,
                amount,
                sales_org,
                is_address_filled,
                order,
                account
            })


    }

    getPaymentOffers(gateway) {
        return axios.get( `/apis/subscription/v1/getoffers/` + gateway);
    }

    addNewLeadGenerate(mobile, schemeId, utmSource) {
        return axios.post( `/apis/subscription/v1/order/add-lead-initial`, ({
            "mobile": mobile,
            "scheme_id": schemeId,
            "utm_source": utmSource,
            "initiator": "acquisition_journey"
        }));
    }

    addDeliveryAdress(dataObj) 
    {
        return axios.post( `/apis/subscription/v1/orders/update-address`,dataObj)
        .then(response => {
            return response.data;
        });
    }

   getUserConsent(accountId) {
        return axios.get( `/apis/subscription/v1/user-consents/get`, ({
            params: {
                "userId": accountId,
                "medium": 2
            }
        })).then(response => {
            return response;
        }).catch(error => {
            console.log('getUserConsent error', error)
        });
   }

   insertUserConsent(accountId, piConsent=true, cookieConsent=true) {
        return axios.post( `/apis/subscription/v1/user-consents/insert`, ({
            "userId": accountId,
            "piConsent": piConsent,
            "cookieConsent": cookieConsent,
            "medium": 2
        })).then(response => {
            return response;
        }).catch(error => {
            console.log('insertUserConsent error', error)
        });
   }

}

export default new UserService();
