import React, { Component, Fragment } from 'react';  
import { Modal } from 'react-bootstrap';  
import './css/sso.css';

class LogoutSSO extends Component {  
    constructor(props) {  
        super(props);  
        this.state = {  
        };  
    }  
    
   componentDidMount() 
   {
    this.removeCookie();
   }
  
    thisDomainCookeiName=()=>{
        return "; domain=ht-subscription-staging.herokuapp.com;path=/";
    }
	
    redirectUSER=(referralURL)=>{
			try{
				window.location.href = referralURL;
			}
			catch(e){
				window.location.href = referralURL;
			}
	}

	redirectURL=()=>{
		var domainName = this.redictectUSERtoNext();
		var date = new Date();
		var getTime = date.getTime();

		if(domainName.includes("accounts.hindustantimes.com")){
			return domainName + '?' + getTime;
		}else{
			return domainName+"logout.html" + '?' + getTime;
		}
	}

	isEmpty=(obj)=>{
		if(typeof obj !="undefined" && typeof obj!=null && obj!=""){
			return false;
		}
		else{
			return true;
		}
	}


	redictectUSERtoNext=()=>{
    	var thisURL = window.location.href;
    	var nextDomain = "https://accounts.hindustantimes.com/logout_successful";
    	var thisDomain = this.extractHostname(thisURL);
    	if(!this.isEmpty(this.ssoDomainList)){
    		for(var i = 0; i < this.ssoDomainList.length; i++){
    			if(this.ssoDomainList[i].includes(thisDomain)){
    				if(i+1 >= this.ssoDomainList.length){
    					break;
    				}else{
    					var nextDomain = this.ssoDomainList[i+1];
    					break;
    				}
    			}
    		}

    	}
    	return nextDomain;
    }

    extractHostname=(url)=> {
	    var hostname;
	    if (url.indexOf("//") > -1) {
	        hostname = url.split('/')[2];
	    }
	    else {
	        hostname = url.split('/')[0];
	    }
	    hostname = hostname.split(':')[0];
	    hostname = hostname.split('?')[0];
	    return hostname;
	}

   removeCookie=()=>{
  	var date = new Date();
	date.setTime(date.getTime()-(2*24*60*60*1000));
	var expires = "; expires="+date.toGMTString();
	var domain  = this.thisDomainCookeiName();
	document.cookie="token=''" + expires  + domain;
	document.cookie="_ht_clientid=''" + expires + domain;
	document.cookie="name=''" + expires + domain;

	this.redirectUSER(this.redirectURL());

  }

  
    render() 
    {  
        return (  
            <div>
                <div style={{ text: 'center', marginTop:'20%' }}>
        	        <div class="loader"></div>
                </div>
            </div>
        );  
    }  
}  
  
export default (LogoutSSO); 